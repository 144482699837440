<template>
  <div class="main-container">
    <div class="bread">
      <router-link :to="{name:lastRoute.name}">{{lastRoute.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">考点练习</span>
    </div>
    <div class="main-content clear" v-if="list.length>0">
      <div class="f-left">
        <ExamQuestion v-if="current" :ques="current" :answer="answer" @change="onChange(current)"></ExamQuestion>
        <div class="clear operate">
          <div class="f-left" @click="collect" v-if="current.collect_id>0">
            <img src="../../images/exam/point/collect-has@3x.png" style="width:17px;" />
            <span class="primary-color">取消收藏</span>
          </div>
          <div class="f-left" @click="collect" v-else>
            <img src="../../images/exam/point/collect@2x.png" style="width:17px;" />
            <span>收藏</span>
          </div>
          <div class="f-right" @click="onCorrect">
            <span>纠错</span>
            <img src="../../images/exam/point/hand@2x.png" style="width:15px;" />
          </div>
        </div>
      </div>
      <div class="f-right">
        <div class="time clear">
          <div class="f-left">
            <img src="../../images/exam/package/clock@2x.png" style="width:20px;" />
            <span>用时</span>
            <span>{{time}}</span>
          </div>
          <div class="f-right" @click="isPause=!isPause">
            <span v-if="!isPause">
              <img src="../../images/exam/point/pause@2x.png" class="icon" style="width:10px;" />
              <span>暂停</span>
            </span>
            <span v-else>
              <i class="el-icon-caret-right icon"></i>
              <span>开始</span>
            </span>
          </div>
        </div>
        <div class="topic-nav clear">
          <a class="f-left" @click="navPrev">上一题</a>
          <a class="f-right" @click="navNext">下一题</a>
        </div>
        <div class="answer-view">
          <div class="progress">
            <span>答题卡</span>
            <span class="primary-color">{{answerCount}}</span>
            <span>/{{list.length}}</span>
          </div>
          <div class="nums">
            <a
              href="javascript:void(0)"
              class="num"
              :class="{'done':answerState[key]}"
              :key="key"
              v-for="(value,key) in answerState"
              @click="index=key-1"
            >{{key}}</a>
          </div>
          <div class="legend">
            <span class="num done"></span>
            <span>已做</span>
            <span class="num"></span>
            <span>未做</span>
          </div>
          <div class="submit" @click="submit()">立即提交</div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="isPause" width="576px">
      <div class="dialog-info">
        <img src="../../images/exam/point/rest@2x.png" />
        <span>休息一会儿吧！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="isPause = false">继续答题</el-button>
      </span>
    </el-dialog>
    <el-dialog title="纠错" :visible.sync="showCorrection" width="644px" :center="true">
      <div class="form-item">
        <label>纠错类型：</label>
        <el-radio-group v-model="correction.type">
          <el-radio :label="1">错别字</el-radio>
          <el-radio :label="2">答案解析不匹配</el-radio>
          <el-radio :label="3">解析错误</el-radio>
          <el-radio :label="4">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="form-item">
        <label>纠错内容：</label>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入题目纠错信息，最多不超过500个字"
          v-model="correction.error_msg"
          :maxlength="500"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="showCorrection = false">取消</el-button>
        <el-button type="primary" @click="submitCorrection">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ExamTopicType } from "@/utils/enums";
import ExamQuestion from "../../components/ExamQuestion.vue";
export default {
  name: "ExamPointDo",
  components: {
    ExamQuestion
  },
  data() {
    return {
      list: [],
      index: 0,
      answer: {},
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      seconds: 0,
      time: "00:00:00",
      timer: null,
      answerCount: 0,
      answerState: {},
      isPause: false,
      isSubmit: false,
      showCorrection: false,
      isReset: undefined,
      correction: {
        type: "",
        error_msg: ""
      },
      lastRoute: {
        name: this.$route.query.route_name || "ExamPoint",
        label: this.$route.query.route_label || "考点专练"
      }
    };
  },
  computed: {
    current() {
      return this.list[this.index];
    }
  },
  async created() {
    let data;
    if (this.$route.query.retry) {
      const responseData = await this.$http.get(
        `/api/app/exam/point/reset/${this.$route.query.report_id}`
      );
      data = responseData.question;
      this.isReset = responseData.is_reset;
    } else {
      data = await this.$http.get(
        "/api/app/examPoint/questions/" +
          this.$route.query.id +
          "/" +
          this.$route.query.number
      );
    }
    let index = 1;
    data.forEach(ques => {
      if (typeof ques.ques_option === "string") {
        ques.ques_option = JSON.parse(ques.ques_option);
      }
      this.$set(this.answerState, index, 0);
      ques.index = index;
      ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
      if (ques.ques_type == 1 || ques.ques_type == 3) {
        this.$set(this.answer, ques.id, "");
      } else if (ques.ques_type == 2) {
        this.$set(this.answer, ques.id, []);
      } else if (ques.ques_type == 4) {
        let answer = {};
        ques.ques_option.left.forEach(op => {
          answer[op.id] = "";
        });
        this.$set(this.answer, ques.id, answer);
      }
      index++;
    });
    this.list = data;

    this.timer = setInterval(() => {
      if (this.isPause || this.isSubmit) {
        return;
      }
      this.seconds++;
      let seconds = this.seconds;
      let time = "";
      let hours = parseInt(seconds / 60 / 60);
      time += hours < 10 ? "0" + hours.toString() : hours.toString();
      let minutes = parseInt((seconds / 60) % 60);
      time +=
        ":" + (minutes < 10 ? "0" + minutes.toString() : minutes.toString());
      let surplus_seconds = seconds % 60;
      time +=
        ":" +
        (surplus_seconds < 10
          ? "0" + surplus_seconds.toString()
          : surplus_seconds.toString());
      this.time = time;
    }, 1000);
  },
  methods: {
    onChange(ques) {
      if (ques.ques_type == 1 || ques.ques_type == 3) {
        if (this.answer[ques.id] != 0 && this.answerState[ques.index] != 1) {
          this.$set(this.answerState, ques.index, 1);
          this.answerCount++;
        }
      } else if (ques.ques_type == 2) {
        if (this.answer[ques.id].length > 0) {
          if (this.answerState[ques.index] != 1) {
            this.$set(this.answerState, ques.index, 1);
            this.answerCount++;
          }
        } else {
          if (this.answerState[ques.index] == 1) {
            this.$set(this.answerState, ques.index, 0);
            this.answerCount--;
          }
        }
      } else if (ques.ques_type == 4) {
        let state = 1;
        let lineAnswer = this.answer[ques.id];
        for (let key in lineAnswer) {
          if (lineAnswer[key] == 0) {
            state = 0;
            break;
          }
        }
        if (state == 1 && this.answerState[ques.index] == 0) {
          this.$set(this.answerState, ques.index, state);
          this.answerCount++;
        }
      }
    },
    navPrev() {
      if (this.index == 0) {
        this.$info("已至第一题");
        return;
      }
      this.index--;
    },
    navNext() {
      if (this.index == this.list.length - 1) {
        this.$info("已至最后一题");
        return;
      }
      this.index++;
    },
    async submit() {
      this.isSubmit = true;
      let count = 0;
      let quesCount = 0;
      for (let key in this.answerState) {
        quesCount++;
        if (this.answerState[key] == 0) {
          count++;
        }
      }
      // 如果没有做任何题
      if (count == quesCount) {
        await this.$promiseConfirm(
          '<span class="dialog-info">您已做<span class="primary-color">0</span>道题，是否退出答题？</span>',
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "继续答题",
            cancelButtonText: "退出答题",
            distinguishCancelAndClose: true,
            callback: action => {
              if (action == "cancel") {
                this.$router.go(-1);
              }
            }
          }
        );
        return;
      }
      if (count > 0) {
        await this.$promiseConfirm(
          '<span class="dialog-info">您还有<span class="primary-color">' +
            count +
            "</span>道题未做，确定要提交吗？</span>",
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "确认交卷",
            cancelButtonText: "继续答题",
            callback: () => {
              this.isSubmit = false;
            }
          }
        );
      } else {
        await this.$promiseConfirm(
          '<span class="dialog-info">确定要提交吗？</span>',
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "确认交卷",
            cancelButtonText: "继续答题",
            callback: () => {
              this.isSubmit = false;
            }
          }
        );
      }
      let result = {};
      for (let key in this.answer) {
        let ques = this.list.filter(q => q.id == key)[0];
        let state = this.answerState[ques.index];
        if (state == 0 && this.$route.query.number != 15) {
          continue;
        }
        result[ques.id] = { report_type: ques.ques_type };
        if (state == 1) {
          if (ques.ques_type == 1 || ques.ques_type == 3) {
            result[ques.id].user_answer = [this.answer[key]];
          } else if (ques.ques_type == 2) {
            result[ques.id].user_answer = this.answer[key];
          } else if (ques.ques_type == 4) {
            result[ques.id].user_answer = this.answer[key];
          }
        } else {
          result[ques.id].user_answer = [];
        }
      }
      // isReset 有值才传，否则不传
      const params = {
        question: JSON.stringify(result),
        paper_id: this.$route.query.id,
        report_time_long: this.seconds
      };
      if (this.isReset !== undefined) {
        params.is_reset = this.isReset;
      }
      let data = await this.$http.post(
        "/api/app/exam/point/storeReport",
        params
      );
      if (this.$route.query.retry) {
        this.$router.push({
          name: "ExamPointAnalysis",
          query: {
            id: data.report_id,
            route_name: this.$route.query.route_name,
            route_label: this.$route.query.route_label
          }
        });
      } else {
        this.$router.push({
          name: "ExamPointReport",
          query: {
            id: data.report_id,
            route_name: this.$route.query.route_name,
            route_label: this.$route.query.route_label,
            number: this.$route.query.number
          }
        });
      }
    },
    async submitCorrection() {
      if (this.correction.type === "") {
        this.$info("请选择纠错类型");
        return;
      }
      if (this.correction.error_msg === "") {
        this.$info("请输入纠错内容");
        return;
      }
      await this.$http.post("/api/app/myExam/correction", {
        ques_id: this.current.id,
        type: this.correction.type,
        error_msg: this.correction.error_msg
      });
      this.showCorrection = false;
      this.$success("提交成功");
    },
    async collect() {
      if (this.current.collect_id > 0) {
        await this.$http.put(
          "/api/app/collect/cancel/" + this.current.collect_id + "/3"
        );
        this.current.collect_id = null;
        this.$success("取消收藏成功");
      } else {
        this.current.collect_id = await this.$http.post("/api/app/collect", {
          ques_id: this.current.id,
          type: 3
        });
        this.$success("收藏成功");
      }
    },
    onCorrect() {
      this.showCorrection = true;
      this.correction.error_msg = "";
      this.correction.type = "";
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.main-content {
  margin-top: 30px;
  margin-bottom: 94px;
  > .f-left {
    width: 890px;
    background-color: white;
    /deep/ .options {
      padding-left: 25px;
    }
  }
  > .f-right {
    width: 290px;
  }
}
.time {
  color: #333333;
  margin-bottom: 15px;
  .f-left,
  .f-right {
    padding: 12px 0;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  .f-left {
    background-color: white;
    width: 177px;
  }
  .f-right {
    background-color: white;
    width: 96px;
    cursor: pointer;
    font-size: 16px;
    > span {
      display: flex;
      align-items: center;
    }
    .icon {
      margin-right: 10px;
    }
    span {
      margin: 0 !important;
      color: #595959 !important;
    }
    .el-icon-caret-right {
      font-size: 20px;
      margin-right: 6px;
    }
  }
  span:nth-child(2) {
    margin: 0 15px;
  }
  span:last-child {
    color: $primary-color;
  }
}
.answer-view {
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 15px;
  .progress {
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    font-size: 16px;
    .primary-color {
      font-size: 18px;
    }
  }
  .nums {
    background-color: white;
    padding: 0 12px 12px 12px;
    margin-bottom: 15px;
  }
  .num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 3px;
    color: #b8b8b8;
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
    &.done {
      background-color: $primary-color;
      color: white;
    }
    &:nth-child(7) {
      margin-right: 0;
    }
  }
  .legend {
    background-color: white;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .num {
      margin-top: 0;
      margin-right: 5px;
      width: 14px;
      height: 14px;
      cursor: default;
    }
    span:nth-child(2) {
      margin-right: 35px;
    }
  }
  .submit {
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    padding: 12px 0;
    cursor: default;
  }
}
.topic-nav a {
  width: 137px;
  height: 45px;
  display: inline-block;
  border: 1px solid rgba(233, 236, 236, 1);
  border-radius: 4px;
  background-color: white;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}
.dialog-info {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 63px;
    margin-right: 27px;
  }
}
.operate {
  font-size: 16px;
  color: #595959;
  font-weight: 400;
  padding: 30px 60px;
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .f-left img {
    margin-right: 5px;
  }
  .f-right img {
    margin-left: 5px;
  }
}
</style>
<style lang="scss">
.dialog-info {
  color: #999999;
  font-size: 20px;
  .primary-color {
    font-size: 30px;
  }
}
.dialog-point {
  width: 576px;
  .el-message-box__content {
    margin: 36px 0 50px 0;
  }
}
</style>