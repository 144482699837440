<template>
  <el-card
    class="card"
    shadow="hover"
    @click.native="goToExam(item)"
  >
    <div class="card-content">
      <div class="card-top">
        <div class="exercise-info">
          <p class="name">{{item.name}}</p>
          <p class="level">{{item.level}}</p>
          <p v-if="item.related_course_id > 0" class="student-free">学员免费</p>
        </div>
        <div class="indexpic">
          <img :src="item.indexpic" alt />
        </div>

      </div>
      <div class="card-bottom clearfix">
        <span class="sales-num">{{item.total_sales_num}}人做题</span>
        <span class="f-left price free" v-if="item.is_free == 0 ">免费</span>
        <span class="f-left price" v-else>
          <Currency class="currency"></Currency> {{item.price/100}}</span>
      </div>
    </div>
  </el-card>
</template>
<script>
export default {
  name: "ExamSimulationItem",
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    goToExam(item) {
      this.$router.push({
        name: "ExamSimulationDes",
        params: { id: item.id }
      });
    },
  },
  created() {
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.card {
  width: 390px;
  height: 239px;
  margin-bottom: 20px;
  cursor: pointer;
  /deep/ .el-card__body {
    height: 100%;
    box-sizing: border-box;
    padding: 0;
  }
  .card-content {
    text-align: center;
    position: relative;
    height: 100%;

    .card-top{
      height: 180px;
      border-bottom: 1px solid #F1F1F1;
      display: flex;
      flex: 1 0 auto;
    }
    .indexpic img {
      width: 133px;
      height: 133px;
      padding: 20px;
    }
    .exercise-info{
      padding: 20px;
      width: 192px;
      text-align: left;
      .name {
        font-size: 18px;
        font-weight: 500;
        color: #3E3A39;
      }
    }
    .card-bottom{
      line-height: 60px;
      .sales-num {
        float: left;
        padding-left: 17px;
        color: #666666;
        font-size: 12px;
      }
      .price{
        float: right;
        padding-right: 18px;
        font-size: 20px;
        font-weight: 500;
        color: #F1000E;
        &.free{
          color: #65AE58;
        }
      }
    }
    .student-free{
      border: 1px solid #008AFD;
      color: #3B90FF;
      border-radius: 4px;
      display: inline-block;
      font-size: 12px;
      width: 64px;
      height: 24px;
      line-height: 25px;
      text-align: center;
      margin-top: 20px;
    }
  }
}
.currency {
    width: 14px;
}
</style>
