<template>
  <div class="main-container">
    <div class="bread" v-if="$route.query.type=='s'">
      <router-link v-if="$route.query.action=='preview'" :to="{name:'ExamSimulation'}">模拟考试</router-link>
      <router-link
        v-else
        :to="{name:lastRouteSim.name, query:lastRouteSim.query}"
      >{{lastRouteSim.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">试卷详情</span>
    </div>
    <div class="bread" v-else>
      <router-link :to="{name:lastRoutePra.name}">{{lastRoutePra.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">试卷详情</span>
    </div>
    <div class="header">
      <div class="name">{{paper.package_name}}</div>
      <div class="des">
        <img src="../../images/exam/package/rect-rotate@2x.png" style="width:14px;" />
        <span>本卷共{{paper.question_num}}题</span>
        <span v-if="paper.limit_time">作答时间为{{paper.limit_time>0?(paper.limit_time/60)+"分钟":"无限制"}}</span>
        <span>总分{{paper.score}}分</span>
        <span>通过分数{{paper.pass_score}}分</span>
      </div>
    </div>
    <div class="main-content clear">
      <div class="f-left">
        <div class="section" v-for="item in list" :key="item.section.package_section_id">
          <div class="title">
            <span>{{item.section.section_name}}</span>
            <span>{{item.section.section_description}}</span>
            <span>(共{{item.section.section_question_num}}道题)</span>
          </div>
          <ExamQuestion
            v-for="ques in item.question"
            :key="ques.id"
            :ques="ques"
            :answer="answer"
            :readonly="readonly"
            @change="onChange(ques)"
          ></ExamQuestion>
        </div>
      </div>
      <div
        class="f-right"
        :style="{position:fixed?'fixed':'static',right:fixedRight+'px',top:'84px'}"
      >
        <div class="time" v-if="!readonly">
          <img src="../../images/exam/package/clock@2x.png" style="width:20px;" />
          <span>用时</span>
          <span>{{time}}</span>
        </div>
        <div class="answer-view">
          <div class="progress">
            <span>答题卡</span>
            <span class="primary-color">{{answerCount}}</span>
            <span>/{{total}}</span>
          </div>
          <div class="nums">
            <a
              href="javascript:void(0)"
              class="num"
              :class="{'done':answerState[key]}"
              :key="key"
              v-for="(value,key) in answerState"
              @click="scrollTo(key)"
            >{{key}}</a>
          </div>
          <div class="legend" v-if="!readonly">
            <span class="num done"></span>
            <span>已做</span>
            <span class="num"></span>
            <span>未做</span>
          </div>
          <div class="submit" @click="submit(false)" v-if="!readonly">交卷</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { ExamTopicType } from "@/utils/enums";
import ExamQuestion from "../../components/ExamQuestion.vue";
export default {
  name: "ExamPackagePracticeDo",
  components: {
    ExamQuestion
  },
  data() {
    return {
      paper: {},
      list: [],
      questions: [],
      answer: {},
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      seconds: 0,
      time: "00:00:00",
      timer: null,
      total: 0,
      answerCount: 0,
      answerState: {},
      fixed: false,
      fixedRight: 0,
      readonly: false,
      lastRouteSim: {
        name: this.$route.query.route_name || "ExamPackagePracticeDes",
        label: this.$route.query.route_label || "考试说明",
        query: this.$route.query.route_name
          ? {}
          : { type: "s", id: this.$route.query.id }
      },
      lastRoutePra: {
        name: this.$route.query.route_name || "ExamPackagePractice",
        label: this.$route.query.route_label || "套卷练习"
      },
      showWaring: false,
      canQuit: 0,
      localKey: null,
      index: 1
    };
  },
  async created() {
    this.readonly = this.$route.query.action == "preview";
    let data,questions,history;
    this.localKey = this.$route.query.id + localStorage.getItem('user_id');
    if (this.$route.query.type == "s") {
      data = await this.$http.get(
        "/api/app/exam/simulation/showQuestion/" + this.$route.query.id,
        { action: this.$route.query.action }
      );
    } else {
      data = await this.$http.get(
        "/api/app/exam/practice/showQuestion/" + this.$route.query.id
      );
    }
    history = await this.$http.get('/api/app/exam/setrecord/?key=' + this.localKey);
    history = JSON.parse(history.value?history.value:'{}');
    let history_question = history.question
    data.package.scocre = parseFloat(data.package.scocre);
    data.package.pass_score = parseFloat(data.package.pass_score);
    questions = data.detail[0].question;
    this.paper = data.package;
    let index = 1;
    if(history_question){
      this.index = history.index;
      this.seconds = history.seconds;
      let arr_new = [];
      for (let i in history_question) {
        history_question[i].id = i;
        arr_new.push(history_question[i]);
      }
      questions.forEach((i,n)=>{
        if (questions[n].ques_type == 2) {
          this.$set(this.answer, questions[n].id, []);
        }
        this.$set(this.answerState, n+1, 0);
        arr_new.forEach((item,index)=>{
          if(item.id == i.id){
            if (questions[n].ques_type == 1 || questions[n].ques_type == 3) {
              this.$set(this.answer, questions[n].id, item.user_answer[0]);
            } else if (questions[n].ques_type == 2) {
              this.$set(this.answer, questions[n].id, item.user_answer);
            }
            if(item.user_answer.length && item.user_answer[0]) {
              this.answerCount++;
              this.$set(this.answerState, n+1, 1);
            }
          }
        })
      })
    }
    data.detail.forEach(item => {
      item.question.forEach(ques => {
        this.questions.push(ques);
        ques.index = index;
        ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
        if(!history_question){
          this.$set(this.answerState, index, 0);
          if (ques.ques_type == 1 || ques.ques_type == 3) {
            this.$set(this.answer, ques.id, "");
          } else if (ques.ques_type == 2) {
            this.$set(this.answer, ques.id, []);
          } else if (ques.ques_type == 4) {
            let answer = {};
            ques.ques_option.left.forEach(op => {
              answer[op.id] = "";
            });
            this.$set(this.answer, ques.id, answer);
          }
        }
        index++;
      });
    });
    this.list = data.detail;
    this.total = index - 1;
    if (!this.readonly) {
      this.timer = setInterval(() => {
        this.seconds++;
        let seconds = this.seconds;
        let time = "";
        let hours = parseInt(seconds / 60 / 60);
        time += hours < 10 ? "0" + hours.toString() : hours.toString();
        let minutes = parseInt((seconds / 60) % 60);
        time +=
          ":" + (minutes < 10 ? "0" + minutes.toString() : minutes.toString());
        let surplus_seconds = seconds % 60;
        time +=
          ":" +
          (surplus_seconds < 10
            ? "0" + surplus_seconds.toString()
            : surplus_seconds.toString());
        this.time = time;
        let surplus = this.paper.limit_time - seconds;
        if (surplus <= 300 && !this.showWaring && this.paper.limit_time > 0) {
          this.$warning(
            "考试即将结束，" +
              (surplus > 60
                ? parseInt(surplus / 60) +
                  "分" +
                  (surplus % 60 > 0 ? (surplus % 60) + "秒" : "")
                : surplus + "秒") +
              "后考试内容将自动提交",
            {
              duration: 3000,
              showClose: true
            }
          );
          this.showWaring = true;
        }
        if (this.paper.limit_time > 0 && seconds >= this.paper.limit_time) {
          clearInterval(this.timer);
          this.$alert("考试已结束，即将跳转考试成绩单！", "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.submit(true);
            }
          });
          return;
        }
        this.time = time;
      }, 1000);
    }
  },
  beforeDestroy() {
    Message.closeAll();
    if (this.timer) {
      clearInterval(this.timer);
    }
    let $ = window.$;
    $(window).unbind("scroll");
  },
  mounted() {
    this.$nextTick(() => {
      let $ = window.$;
      $(window)
        .scroll(() => {
          this.setFixed();
        })
        .resize(() => {
          this.setFixed();
        });
    });
  },
  methods: {
    setFixed() {
      let $ = window.$;
      if ($(window).scrollTop() + 84 > 413) {
        this.fixed = true;
        this.fixedRight = ($(window).width() - 1200) / 2;
        if (this.fixedRight < 0) {
          this.fixedRight = 0;
        }
      } else {
        this.fixed = false;
      }
    },
    onChange(ques) {
      this.index = ques.index-1;
      if (ques.ques_type == 1 || ques.ques_type == 3) {
        if (this.answer[ques.id] != 0 && this.answerState[ques.index] != 1) {
          this.$set(this.answerState, ques.index, 1);
          this.answerCount++;
        }
      } else if (ques.ques_type == 2) {
        if (this.answer[ques.id].length > 0) {
          if (this.answerState[ques.index] != 1) {
            this.$set(this.answerState, ques.index, 1);
            this.answerCount++;
          }
        } else {
          if (this.answerState[ques.index] == 1) {
            this.$set(this.answerState, ques.index, 0);
            this.answerCount--;
          }
        }
      } else if (ques.ques_type == 4) {
        let state = 1;
        let lineAnswer = this.answer[ques.id];
        for (let key in lineAnswer) {
          if (lineAnswer[key] == 0) {
            state = 0;
            break;
          }
        }
        if (state == 1 && this.answerState[ques.index] == 0) {
          this.$set(this.answerState, ques.index, state);
          this.answerCount++;
        }
      }
    },
    async submit(auto) {
      if (!auto) {
        let count = 0;
        for (let key in this.answerState) {
          if (this.answerState[key] == 0) {
            count++;
          }
        }
        if (count > 0) {
          await this.$promiseConfirm(
            "您还有" + count + "道题未作答，确认交卷吗？",
            { type: "warning" }
          );
        } else {
          await this.$promiseConfirm("确定要提交吗？", { type: "warning" });
        }
      }
      let result = this.setAnswer();
      let url;
      if (this.$route.query.type == "s") {
        url = "/api/app/exam/simulation/storeReport";
      } else {
        url = "/api/app/exam/practice/storeReport";
      }
      let data = await this.$http.post(url, {
        question: JSON.stringify(result),
        paper_id: this.$route.query.id,
        report_time_long: this.seconds
      });
      this.setRecord(this.localKey,'{}')
      this.canQuit = 1;
      this.$route.params.allow = true;
      this.$router.push({
        name: "ExamPackagePracticeScore",
        query: {
          id: data.report_id,
          type: this.$route.query.type,
          route_name: this.$route.query.route_name,
          route_label: this.$route.query.route_label
        }
      });
    },
    setAnswer (){
      let result = {};
      for (let key in this.answer) {
        let ques = this.questions.filter(q => q.id == key)[0];
        let state = this.answerState[ques.index];
        result[ques.id] = { report_type: ques.ques_type };
        if (state == 1) {
          if (ques.ques_type == 1 || ques.ques_type == 3) {
            result[ques.id].user_answer = [this.answer[key]];
          } else if (ques.ques_type == 2) {
            result[ques.id].user_answer = this.answer[key];
          } else if (ques.ques_type == 4) {
            result[ques.id].user_answer = this.answer[key];
          }
        } else {
          result[ques.id].user_answer = [];
        }
      }
      return result;
    },
    scrollTo(id) {
      let $ = window.$;
      let top = $("#" + id).offset().top - 84;
      $(window).scrollTop(top);
    },
    mathJax(){
      let _this = this;
      if (this.MathJax.isMathjaxConfig) { // 判断是否初始配置，若无则配置。
        this.MathJax.initMathjaxConfig()
      }
      setTimeout(function(){
        _this.MathJax.MathQueue()// 传入组件id，让组件被MathJax渲染
      },1000)
    },
    async setRecord (key,value) {
      await this.$http.post('/api/app/exam/setrecord',{key:key,value:value}).then(res=>{
      })
    }
  },
  mounted() {
    this.mathJax();
  },
  async beforeRouteLeave (to, from, next) {
    if(!this.canQuit){
      await this.$promiseConfirm('退出后是否保存答题结果？若不保存下次答题将从头开始',{
            type: "warning",
            confirmButtonText: '保存记录',
            cancelButtonText: '直接退出',
            distinguishCancelAndClose: true,
            callback: action => {
              if (action == "cancel") {
                this.setRecord(this.localKey,'{}')
                // localStorage.removeItem(this.localKey)
                this.canQuit = 1;
                next();
              }
            }
          }
        ).then(()=>{
          let result = this.setAnswer();
          let params = {
            index: this.index,
            question: result,
            seconds: this.seconds
          }
          if(JSON.stringify(result) != "{}"){
            this.setRecord(this.localKey,JSON.stringify(params))
            // localStorage.setItem(this.localKey,JSON.stringify(params));
          }
          next();
        })
      next(false)
    } else {
      next();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.header {
  background-color: white;
  padding: 36px 0;
  text-align: center;
  margin-bottom: 30px;
  .name {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    padding-bottom: 33px;
    border-bottom: 1px dashed #dedede;
    margin: 0 40px;
    margin-bottom: 25px;
  }
  .des {
    font-size: 16px;
    color: #999999;
    img {
      margin-right: 14px;
    }
    span {
      margin-right: 28px;
    }
  }
}
.main-content {
  margin-bottom: 50px;
  .f-left {
    width: 890px;
    background-color: white;
  }
  .f-right {
    width: 290px;
  }
}
.time {
  justify-content: center;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 12px 0;
  color: #333333;
  margin-bottom: 15px;
  span:nth-child(2) {
    margin: 0 15px;
  }
  span:last-child {
    color: $primary-color;
  }
}
.answer-view {
  box-sizing: border-box;
  border-radius: 5px;
  .progress {
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    font-size: 16px;
    .primary-color {
      font-size: 18px;
    }
  }
  .nums {
    background-color: white;
    padding: 0 12px 12px 12px;
    margin-bottom: 15px;
    max-height: 200px;
    overflow-y: scroll;
  }
  .num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 3px;
    color: #b8b8b8;
    margin-top: 12px;
    margin-right: 10px;
    cursor: pointer;
    &.done {
      background-color: $primary-color;
      color: white;
    }
    &:nth-child(7n) {
      margin-right: 0;
    }
  }
  .legend {
    background-color: white;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .num {
      margin-top: 0;
      margin-right: 5px;
      width: 14px;
      height: 14px;
      cursor: default;
    }
    span:nth-child(2) {
      margin-right: 35px;
    }
  }
  .submit {
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    padding: 12px 0;
    cursor: default;
  }
}
.section {
  .title {
    background-color: #f7f7f7;
    padding: 16px 25px;
    font-size: 18px;
    color: #262626;
    span {
      margin-right: 5px;
    }
  }
}
</style>