<template>
  <div class="main-container">
    <div class="bread">
      <router-link :to="{name:'Home'}">首页</router-link>
      <span class="slash"> > </span>
      <span class="current">模拟考试</span>
    </div>
    <div class="select-tab">
      <Classify ref='classify'
      :classify_id='classify_id'
      :all="true"
      @changeClassify="changeClassify"/>
      <div class="item" v-for="(attrItem, index) in attrs" :key="index">
        <div class="left">
          <p>{{attrItem.attr}}：</p>
        </div>
        <div class="right">
          <ul>
            <li
              v-for="(valueItem, index) in attrItem.value"
              :key="index"
              :class="{active: attrCondition[attrItem.id] == valueItem.id}"
              @click="onClickAttr(attrItem.id, valueItem.id)"
            >{{valueItem.name}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="clear list">
      <ExamSimulationItem v-for="item in list" :key="item.id" :item="item" :showName="true">
      </ExamSimulationItem>
      <div
        class="empty-card"
        v-for="item in (cols - list.length % cols)"
        :key="list.length + item"
      ></div>
    </div>
    <el-pagination
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="limit"
      layout="prev, pager, next"
      :total="total"
      v-if="total>limit"
    ></el-pagination>
  </div>
</template>
<script>
import Classify from "../../components/Classify.vue";
import ExamSimulationItem from "../../components/ExamSimulationItem.vue";
export default {
  name: "ExamSimulation",
  components: {
    ExamSimulationItem,
    Classify
  },
  data() {
    return {
      exam_type: 1,
      classify_id: 0,
      cols: 3,
      list: [],
      attrs: [],
      attrCondition: {},
      page: 1,
      limit: 6,
      total: 0
    };
  },
  async created() {
    await this.getData();
    await this.requestAttr();
  },
  methods: {
    async getData() {
      const ary = [];
      for (const key in this.attrCondition) {
        if (this.attrCondition[key]) {
          ary.push(this.attrCondition[key]);
        }
      }
      let params = {
        classify_id: this.subClassify || this.classify_id,
        page: this.page,
        limit: this.limit,
        attr_val_id: ary.join()
      };
      let data = await this.$http.get(
        "/api/app/exam/packageSimulation?exam_type=" +
          this.exam_type , params
      );
      this.list = data.list;
      this.total = data.total;
    },
    async changeType(type) {
      this.exam_type = type;
      this.page = 1;
      await this.getData();
    },
    async handlePageChange(page) {
      this.page = page;
      await this.getData();
    },
    async changeClassify(flag,item){
      let id = item.id;
      if(flag == 1){
        this.classify_id = item.id;
        this.subClassify = null;
        this.$refs.classify.showChildClassify = true;
        this.$nextTick(() => {
          this.$refs.classify.childClassify = item.child;
        });
      } else{
        this.subClassify = item.id;
      }
      this.page = 1;
      await this.getData();
    },
    async requestAttr() {
      await  this.$http.get("/api/app/attribute/5").then(data => {
          data.forEach(i => {
            i.value &&
              i.value.unshift({
                id: 0,
                name: "全部"
              });
            this.attrCondition[i.id] = 0;
          });
          this.attrs = data;
        });
    },
    onClickAttr(attrID, valueID) {
      this.$set(this.attrCondition, attrID, valueID);
      this.page = 1;
      this.getData();
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.bread{
  background-color: transparent;
  padding: 0;
  margin: 0;
  height: 62px;
  line-height: 62px;
  font-size: 14px;
  span{
    color: rgba(0, 0, 0, 0.45)!important;
  }
  a {
      color: #333333!important;
  }
}
.select-tab{
  margin-top: 0;
}
.list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.empty-card {
  width: 390px;
}
</style>
