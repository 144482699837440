<template>
  <div class="main-container">
    <div class="clear">
      <div class="f-right top-nav">
        <router-link :to="{name:'MemberEvaluationTest'}"
                     class="record">
          <img src="../../images/exam/index/record@2x.png" />
          <span>测评记录</span>
        </router-link>
        <router-link :to="{name:'MemberEvaluationMistake'}">
          <img src="../../images/exam/index/error@2x.png" />
          <span>错题记录</span>
        </router-link>
        <router-link :to="{name:'MemberEvaluationCollect'}">
          <img src="../../images/exam/index/collect@2x.png" />
          <span>习题收藏</span>
        </router-link>
      </div>
    </div>
    <div class="main-content">
      <div class="nav clear">
        <router-link :to="{name:'ExamPoint'}">
          <div class="item point">
            <div class="content">
              <div class="circle">
                <img src="../../images/exam/index/icon-point@2x.png"
                     style="width:45px;" />
              </div>
              <div class="title">考点专练</div>
            </div>
          </div>
        </router-link>
        <router-link :to="{name:'ExamPackagePractice'}">
          <div class="item package">
            <div class="content">
              <div class="circle">
                <img src="../../images/exam/index/icon-package@2x.png"
                     style="width:44px;" />
              </div>
              <div class="title">套卷练习</div>
            </div>
          </div>
        </router-link>
        <router-link :to="{name:'ExamSimulation'}">
          <div class="item simulation">
            <div class="content">
              <div class="circle">
                <img src="../../images/exam/index/icon-simulation@2x.png"
                     style="width:43px;" />
              </div>
              <div class="title">模拟考试</div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="exam-plan">
        <div class="title bold">近期模考安排</div>
        <div class="sort">
          <a :class="{'active':sort==''}"
             @click="changeSort('')">默认排序</a>
          <a :class="{'active':sort!='','asc':sort=='asc'}"
             @click="changeSort(sort!='desc'?'desc':'asc')">
            时间
            <img src="../../images/exam/index/desc@2x.png"
                 style="width:6px;" />
          </a>
        </div>
        <div class="clear list">
          <ExamSimulationItem v-for="item in list"
                              :key="item.id"
                              :item="item"
                              :showName="true"></ExamSimulationItem>
        </div>
        <el-pagination background
                       @current-change="handlePageChange"
                       :current-page="page"
                       :page-size="limit"
                       layout="prev, pager, next"
                       :total="total"
                       v-if="total>limit"></el-pagination>
        <Empty v-if="list.length==0"
               class="empty"
               title="暂无模考安排！" />
      </div>
    </div>
  </div>
</template>
<script>
import ExamSimulationItem from "../../components/ExamSimulationItem.vue";
export default {
  name: "ExamIndex",
  components: {
    ExamSimulationItem
  },
  data () {
    return {
      sort: "",
      list: [],
      page: 1,
      limit: 15,
      total: 0
    };
  },
  async created () {
    await this.getData();
  },
  methods: {
    async getData () {
      let data = await this.$http.get("/api/app/exam/testIndex", {
        sort: this.sort,
        page: this.page,
        limit: this.limit
      });
      data.list.forEach(e => {
        if (e.is_start == 0) {
          e.start_status = 2;
        } else if (e.is_start == 1) {
          e.start_status = 1;
        } else if (e.is_start == 2) {
          e.start_status = 3;
        }
        e.have_done_count = e.answer_amount || 0;
      });
      this.list = data.list;
      this.total = data.total;
    },
    async handlePageChange (page) {
      this.page = page;
      await this.getData();
    },
    async changeSort (sort) {
      this.sort = sort;
      await this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.top-nav {
  padding: 30px 0;
  a {
    font-size: 15px;
    border-radius: 26px;
    color: #595959;
    padding: 14px;
    display: inline-block;
    background-color: white;
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
    &.record {
      background-color: $primary-color;
      color: white;
    }
    img {
      width: 16px;
      vertical-align: middle;
      margin-right: 6px;
    }
    span {
      vertical-align: middle;
    }
  }
}
.main-content {
  background-color: white;
  margin-bottom: 50px;
}
.nav {
  padding: 35px 60px;
  cursor: pointer;
  .item {
    width: 305px;
    height: 200px;
    background-repeat: no-repeat;
    float: left;
  }
  .point {
    background-image: url(../../images/exam/index/back-point@2x.png);
    background-size: 336px 542px;
    background-position-x: -16px;
    background-position-y: -157px;
  }
  .package {
    background-image: url(../../images/exam/index/back-package@2x.png);
    background-size: 314px 558px;
    background-position-x: -6px;
    background-position-y: -73px;
    margin: 0 82px;
  }
  .simulation {
    background-image: url(../../images/exam/index/back-simulation@2x.png);
    background-size: 336px 558px;
    background-position-x: -16px;
    background-position-y: -89px;
  }
  .circle {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 111px;
    margin-bottom: 24px;
  }
  .content {
    text-align: center;
    color: white;
    font-size: 16px;
    padding-top: 40px;
  }
}
.exam-plan {
  padding: 24px;
  .title {
    font-size: 16px;
    margin-bottom: 23px;
  }
  .sort {
    font-size: 16px;
    display: flex;
    align-items: center;
    a {
      cursor: pointer;
      margin-right: 41px;
      &.active {
        color: $primary-color;
      }
    }
    .asc {
      img {
        transform: rotateX(180deg);
      }
    }
  }
}
.list {
  padding-top: 20px;
  /deep/ .item {
    margin-right: 0;
    margin-left: 16px;
    &:nth-child(3n-2) {
      margin-left: 8px;
    }
  }
}
</style>