<template>
  <div class="main-container">
    <div class="bread"
         v-if="$route.query.type=='s'">
      <router-link :to="{name:lastRouteSim.name}">{{lastRouteSim.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">查看解析</span>
    </div>
    <div class="bread"
         v-else>
      <router-link :to="{name:lastRoutePra.name}">{{lastRoutePra.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">查看解析</span>
    </div>
    <div class="header">
      <div class="name bold">{{paper.package_name}}</div>
      <div class="result">
        <img src="../../images/exam/package/rect-rotate@2x.png"
             style="width:14px;" />
        <span>考试用时{{paper.time}}</span>
        <span>提交时间：{{paper.created_at|tsFormat("yyyy/MM/dd hh:mm:ss")}}</span>
      </div>
      <div class="point bold">{{paper.point}}</div>
    </div>
    <div class="main-content clear">
      <div class="f-left">
        <div class="section"
             v-for="item in list"
             :key="item.section.package_section_id">
          <div class="title">
            <span v-if="$route.query.type !='s'">{{item.section.section_name}}</span>
            <span v-if="$route.query.type !='s'">{{item.section.section_description}}</span>
            <span v-if="$route.query.type !='s'">(共{{item.section.section_question_num}}道题)</span>
          </div>
          <div v-if="$route.query.type=='s'">
            <ExamQuestion ref="question"
                          :ques="questionList[index]"
                          :readonly="true"
                          :hasAnalysis="true"
                          :answer="answer"
                          @change="onChange(questionList[index])"
                          @showAnalysis="answerQuestion(2)">
              <div slot='changeQuestion'
                   class="clear operate">
                <div class="last-question">
                  <a @click="navPrev"> <i class="el-icon-arrow-left"></i> 上一题</a>
                </div>
                <div class="cancel-question"
                     @click="collect"
                     v-if="questionList[index].collect_id>0">
                  <img src="../../images/exam/point/collect-has@3x.png"
                       style="width:17px;" />
                  <span class="primary-color">取消收藏</span>
                </div>
                <div class="collect-question"
                     @click="collect"
                     v-else>
                  <img src="../../images/exam/point/collect@2x.png"
                       style="width:17px;" />
                  <span>收藏</span>
                </div>
                <div class="error-question"
                     @click="onCorrect">
                  <img src="../../images/exam/point/hand@2x.png"
                       style="width:15px;" />
                  <span>纠错</span>
                </div>
                <div class="next-question">
                  <a @click="navNext">下一题 <i class="el-icon-arrow-right"></i> </a>
                </div>
              </div>
            </ExamQuestion>
          </div>
          <ExamQuestion v-else
                        v-for="ques in item.question"
                        :key="ques.id"
                        :ques="ques"
                        :answer="answer"
                        :readonly="true"
                        :hasAnalysis="true"></ExamQuestion>
        </div>
      </div>
      <div class="f-right"
           :style="{position:fixed?'fixed':'static',right:fixedRight+'px',top:'84px'}">
        <div class="answer-view">
          <div class="progress">
            <span>答题卡</span>
            <span class="primary-color">{{answerCount}}</span>
            <span>/{{total}}</span>
          </div>
          <div class="nums">
            <a class="num"
               :class="{'right':answerState[key]==1,'error':answerState[key]==0,'none':answerState[key]==0}"
               :key="key"
               v-for="(value,key) in answerState"
               @click="scrollTo(key)">{{key}}</a>
          </div>
          <div class="legend">
            <span class="num right"></span>
            <span>正确{{rightCount}}</span>
            <span class="num error"></span>
            <span>错误{{errorCount}}</span>
            <span class="num none"></span>
            <span>未做{{noneCount}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ExamTopicType } from "@/utils/enums";
import ExamQuestion from "../../components/ExamQuestion.vue";
export default {
  name: "ExamPackagePracticeAnalysis",
  components: {
    ExamQuestion
  },
  data () {
    return {
      paper: {},
      list: [],
      answer: {},
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      fixed: false,
      fixedRight: 0,
      total: 0,
      answerCount: 0,
      answerState: {},
      rightCount: 0,
      errorCount: 0,
      noneCount: 0,
      lastRouteSim: {
        name: this.$route.query.route_name || "ExamSimulation",
        label: this.$route.query.route_label || "模拟考试"
      },
      lastRoutePra: {
        name: this.$route.query.route_name || "ExamPackagePractice",
        label: this.$route.query.route_label || "套卷练习"
      },
      index: 0,
      questionList: []
    };
  },
  async created () {
    let url;
    if (this.$route.query.type == "s") {
      url = "/api/app/exam/simulation/reportDetail";
    } else {
      url = "/api/app/exam/practice/reportDetail";
    }
    url +=
      "?report_id=" +
      this.$route.query.report_id +
      "&paper_id=" +
      this.$route.query.paper_id;
    let data = await this.$http.get(url);
    let questions = [];
    data.detail.forEach(it=>{
      questions = questions.concat(it.question)
    })
    questions.forEach(ques => {
      if (typeof ques.ques_option === "string") {
        ques.ques_option = JSON.parse(ques.ques_option);
      }
      ques.index = index;
      ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
      ques.right_option = [];
      if (ques.ques_option) {
        ques.ques_option.forEach((op, i) => {
          if (op.is_right == 1) {
            ques.right_option.push(this.chars[i + 1])
          }
        });
      }
      if (ques.ques_type == 1 || ques.ques_type == 3) {
        this.$set(this.answer, ques.id, "");
      } else if (ques.ques_type == 2) {
        this.$set(this.answer, ques.id, []);
      } else if (ques.ques_type == 4) {
        let answer = {};
        ques.ques_option.left.forEach(op => {
          answer[op.id] = "";
        });
        this.$set(this.answer, ques.id, answer);
      }
      index++;
    });
    this.questionList = questions;
    let seconds = data.package.report_time_long;
    let time = "";
    let hours = parseInt(seconds / 60 / 60);
    if (hours > 0) {
      time += (hours < 10 ? "0" + hours.toString() : hours.toString()) + "时";
    }
    let minutes = parseInt((seconds / 60) % 60);
    if (minutes > 0) {
      time +=
        (minutes < 10 ? "0" + minutes.toString() : minutes.toString()) + "分";
    }
    let surplus_seconds = seconds % 60;
    time +=
      (surplus_seconds < 10
        ? "0" + surplus_seconds.toString()
        : surplus_seconds.toString()) + "秒";
    data.package.time = time;
    this.paper = data.package;
    let index = 1;
    data.detail.forEach(item => {
      item.question.forEach(ques => {
        ques.id = ques.ques_id;
        ques.index = index;
        ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
        let answers = [];
        if (ques.user_answer) {
          let user_answer = JSON.parse(ques.user_answer);
          user_answer.forEach(a => {
            if (ques.ques_type == 4) {
              if (a.answer) {
                answers.push(a.id + ")" + this.chars[a.answer] + " ");
              }
            } else {
              if (a.id) {
                answers.push(this.chars[a.id]);
              }
            }
          });
          ques.user_answer = answers.join("");
        }
        if (answers.length > 0) {
          this.answerCount++;
          if (ques.is_right == 1) {
            this.rightCount++;
          } else {
            this.errorCount++;
          }
          this.$set(this.answerState, ques.index, ques.is_right == 1 ? 1 : 0);
        } else {
          this.$set(this.answerState, ques.index, -1);
          this.noneCount++;
        }
        if (ques.ques_type == 1 || ques.ques_type == 3) {
          let id = ques.ques_option.filter(o => o.is_right == 1)[0].id;
          this.$set(this.answer, ques.ques_id, id);
          ques.answer = this.chars[id];
        } else if (ques.ques_type == 2) {
          let ids = [];
          let answers = [];
          ques.ques_option
            .filter(o => o.is_right == 1)
            .forEach(o => {
              ids.push(o.id);
              answers.push(this.chars[o.id]);
            });
          this.$set(this.answer, ques.ques_id, ids);
          ques.answer = answers.join("");
        } else if (ques.ques_type == 4) {
          let answer = {};
          let answers = [];
          ques.ques_option.left.forEach(op => {
            answer[op.id] = op.is_right;
            answers.push(op.id + ")" + this.chars[op.is_right]);
          });
          this.$set(this.answer, ques.ques_id, answer);
          ques.answer = answers.join(" ");
        }
        index++;
      });
    });
    this.total = index - 1;
    if (this.$route.query.type == "s") {
      let question = [];
      let section = []
      data.detail.forEach(i=>{
        question = question.concat(i.question)
        section = i.section;
      })
      this.list.push({question:question,section:section})
    } else {
      this.list = data.detail;
    }
  },
  mounted () {
    this.$nextTick(() => {
      let $ = window.$;
      $(window)
        .scroll(() => {
          this.setFixed();
        })
        .resize(() => {
          this.setFixed();
        });
      this.mathJax();
    });
  },
  methods: {
    setFixed () {
      let $ = window.$;
      if ($(window).scrollTop() + 84 > 413) {
        this.fixed = true;
        this.fixedRight = ($(window).width() - 1200) / 2;
        if (this.fixedRight < 0) {
          this.fixedRight = 0;
        }
      } else {
        this.fixed = false;
      }
    },
    getAnswer () {
      let _this = this;
      setTimeout(() => {
        _this.$refs.question[0].getAnswerList();
      }, 300)
    },
    scrollTo (id) {
      if (this.$route.query.type == 's') {
        this.index = id - 1;
        this.getAnswer();
      } else {
        let $ = window.$;
        let top = $("#" + id).offset().top - 84;
        $(window).scrollTop(top);
      }
    },
    navPrev () {
      if (this.index == 0) {
        this.$info("已至第一题");
        return;
      }
      this.index--;
      this.getAnswer();
      this.mathJax();
    },
    navNext () {
      if (this.index == this.questionList.length - 1) {
        this.$info("已至最后一题");
        return;
      }
      this.index++;
      this.getAnswer();
      this.mathJax();
    },
    async submitCorrection () {
      if (this.correction.type === "") {
        this.$info("请选择纠错类型");
        return;
      }
      if (this.correction.error_msg === "") {
        this.$info("请输入纠错内容");
        return;
      }
      await this.$http.post("/api/app/myExam/correction", {
        ques_id: this.questionList[this.index].id,
        type: this.correction.type,
        error_msg: this.correction.error_msg
      });
      this.showCorrection = false;
      this.$success("提交成功");
    },
    async collect () {
      if (this.questionList[this.index].collect_id > 0) {
        await this.$http.put(
          "/api/app/collect/cancel/" + this.questionList[this.index].collect_id + "/3"
        );
        this.questionList[this.index].collect_id = null;
        this.$success("取消收藏成功");
      } else {
        this.questionList[this.index].collect_id = await this.$http.post("/api/app/collect", {
          ques_id: this.questionList[this.index].id,
          type: 3
        });
        this.$success("收藏成功");
      }
    },
    onCorrect () {
      this.showCorrection = true;
      this.correction.error_msg = "";
      this.correction.type = "";
    },
    mathJax () {
      let _this = this;
      if (this.MathJax.isMathjaxConfig) { // 判断是否初始配置，若无则配置。
        this.MathJax.initMathjaxConfig()
      }
      setTimeout(function () {
        _this.MathJax.MathQueue()// 传入组件id，让组件被MathJax渲染
      }, 1000)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.header {
  background-color: white;
  text-align: center;
  padding-bottom: 35px;
  margin-bottom: 30px;
  position: relative;
  .name {
    font-size: 20px;
    padding: 33px;
    border-bottom: 1px dashed #dedede;
    margin: 0 41px;
    margin-bottom: 25px;
  }
  .result {
    color: #999999;
    span:nth-child(2) {
      margin: 0 28px 0 14px;
    }
  }
  .point {
    width: 96px;
    height: 70px;
    position: absolute;
    color: red;
    font-size: 40px;
    top: 19px;
    right: 47px;
    background-image: url(../../images/exam/package/score-line.png);
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}
.main-content {
  margin-bottom: 50px;
  .f-left {
    width: 890px;
    background-color: white;
  }
  .f-right {
    width: 290px;
  }
  /deep/ {
    .operate {
      margin-top: 40px;
      margin-bottom: -40px;
      font-size: 16px;
      color: #595959;
      font-weight: 400;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      div {
        cursor: pointer;
      }
      .collect-question,
      .cancel-question,
      .error-question {
        display: inline-flex;
      }
      .collect-question img,
      .cancel-question img,
      .error-question img {
        margin-right: 5px;
        width: 17px;
        height: 17px;
        padding-top: 3px;
      }
    }
  }
}
.section {
  .title {
    background-color: #f7f7f7;
    padding: 16px 25px;
    font-size: 18px;
    color: #262626;
    span {
      margin-right: 5px;
    }
  }
}
.answer-view {
  border: 1px solid rgba(233, 236, 236, 1);
  box-sizing: border-box;
  border-radius: 5px;
  .progress {
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    font-size: 16px;
    .primary-color {
      font-size: 18px;
    }
  }
  .nums {
    background-color: white;
    padding: 0 12px 12px 12px;
    margin-bottom: 15px;
    max-height: 185px;
    overflow-y: scroll;
  }
  .num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 3px;
    color: #b8b8b8;
    margin-top: 12px;
    margin-right: 11px;
    cursor: pointer;
    &.right {
      background-color: #30c596;
      color: white;
    }
    &.error {
      background-color: #ff5500;
      color: white;
    }
    &:nth-child(7n) {
      margin-right: 0;
    }
  }
  .legend {
    background-color: white;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .num {
      margin-top: 0;
      margin-right: 5px;
      width: 14px;
      height: 14px;
      cursor: default;
    }
    span:nth-child(2),
    span:nth-child(4) {
      margin-right: 40px;
    }
  }
}
</style>