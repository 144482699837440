<template>
  <div class="main-container">
    <div class="bread">
      <router-link :to="{name:'ExamIndex'}">在线测评</router-link>
      <span class="slash">/</span>
      <span class="current">套卷练习</span>
    </div>
    <div class="select-tab">
      <div class="item">
        <div class="left">
          <p>套卷类别：</p>
        </div>
        <div class="right">
          <div class="classify-wrap">
            <ul>
              <li :class="classify == 0?'active':'' " @click="changeClassify(0)">全部</li>
            </ul>
            <ul>
              <li
                :class="classify == item.id?'active':'' "
                @click="changeClassify(item.id)"
                v-for="item in classifyList"
                :key="item.id"
              >
                {{item.label}}
                <div v-if="classify == item.id&&subClassifyList.length>0" class="select-tab-arrow"></div>
              </li>
            </ul>
          </div>
          <div class="child" v-if="subClassifyList.length">
            <p
              :class="item.id === sub_classify?'active':'' "
              v-for="item in subClassifyList"
              :key="item.id"
              @click="changeSubClassify(item.id)"
            >{{item.label}}</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <p>套卷状态：</p>
        </div>
        <div class="right">
          <ul>
            <li :class="state == 0?'active':'' " @click="changeState(0)">全部</li>
            <li :class="state == 1?'active':'' " @click="changeState(1)">已做</li>
            <li :class="state == 2?'active':'' " @click="changeState(2)">未做</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="clear list" v-if="list.length>0">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="name">{{item.name}}</div>
        <div class="clear bottom">
          <div class="f-left tip">
            <div>本卷共{{item.question_num}}题</div>
            <div>试卷时长：{{item.limit_times}}</div>
          </div>
          <router-link
            class="lnk f-right"
            :to="{name:'ExamPackagePracticeDes',query:{id:item.id}}"
            v-if="item.done_status==2"
          >开始</router-link>
          <div v-else class="clear f-right">
            <router-link
              class="lnk lnk-mini f-left"
              :to="{name:'ExamPackagePracticeScore',query:{id:item.report_id}}"
            >查看成绩</router-link>
            <router-link
              class="lnk lnk-mini f-left"
              :to="{name:'ExamPackagePracticeDo',query:{id:item.id}}"
            >再练一次</router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty">
      <div>
        <img src="../../images/exam/package/empty@2x.png" style="width:176px;" />
      </div>
      <div>请稍后，套卷正在赶来的路上</div>
    </div>
    <el-pagination
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="limit"
      layout="prev, pager, next"
      :total="total"
      v-if="total>limit"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "ExamPackagePractice",
  data() {
    return {
      classify: 0,
      sub_classify: 0,
      state: 0,
      list: [],
      page: 1,
      limit: 6,
      total: 0,
      classifyList: [],
      subClassifyList: []
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.classifyList = (await this.$http.get("/api/app/exam/classify")).rows;
      let data = await this.$http.get("/api/app/exam/packagePractice", {
        done_status: this.state,
        classify_id: this.sub_classify || this.classify,
        page: this.page,
        limit: this.limit
      });
      data.list.forEach(p => {
        if (p.limit_time > 0) {
          let limit_times = "";
          if (p.limit_time > 60) {
            limit_times += parseInt(p.limit_time / 60) + "分";
          }
          if (p.limit_time % 60 > 0) {
            limit_times += (p.limit_time % 60) + "秒";
          }
          p.limit_times = limit_times;
        } else {
          p.limit_times = "无限制";
        }
      });
      this.list = data.list;
      this.total = data.total;
    },
    async changeState(state) {
      this.page = 1;
      this.state = state;
      await this.getData();
    },
    async changeClassify(id) {
      this.classify = id;
      if (id > 0) {
        this.subClassifyList =
          this.classifyList.filter(c => c.id == id)[0].children || [];
      } else {
        this.subClassifyList = [];
      }
      if (this.subClassifyList.length > 0) {
        this.sub_classify = this.subClassifyList[0].id;
      } else {
        this.sub_classify = 0;
      }
      this.page = 1;
      await this.getData();
    },
    async changeSubClassify(id) {
      this.sub_classify = id;
      this.page = 1;
      await this.getData();
    },
    async handlePageChange(page) {
      this.page = page;
      await this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.list {
  padding: 30px 0 30px 0;
  .item {
    width: 386px;
    height: 177px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(238, 238, 238, 1);
    border-radius: 10px;
    float: left;
    margin-right: 18px;
    padding: 24px 32px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .name {
      font-size: 20px;
      font-weight: 500;
      height: 52px;
    }
    .bottom {
      padding-top: 40px;
    }
    .tip {
      font-size: 14px;
      color: #666666;
    }
    .lnk {
      padding: 7px 26px;
      border: 1px solid $primary-color;
      color: $primary-color;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
    .lnk-mini {
      padding: 7px 16px;
      margin-right: 5px;
    }
  }
}
.empty {
  text-align: center;
  padding: 198px 0;
  background-color: white;
  margin-top: 30px;
  font-size: 18px;
  color: #8c8c8c;
  margin-bottom: 50px;
  img {
    margin-bottom: 30px;
  }
}
.select-tab {
  .right {
    .classify-wrap {
      display: flex;
    }
  }
  .item .right li {
    margin-left: 0;
    margin-right: 40px;
  }
}
</style>