import { render, staticRenderFns } from "./ExamPointDo.vue?vue&type=template&id=06e6934b&scoped=true&"
import script from "./ExamPointDo.vue?vue&type=script&lang=js&"
export * from "./ExamPointDo.vue?vue&type=script&lang=js&"
import style0 from "./ExamPointDo.vue?vue&type=style&index=0&id=06e6934b&lang=scss&scoped=true&"
import style1 from "./ExamPointDo.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e6934b",
  null
  
)

export default component.exports