<template>
  <div class="main-container">
    <div class="bread">
      <router-link :to="{name:lastRoute.name}">{{lastRoute.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">题目详情</span>
    </div>
    <div class="main-content clear" v-if="list.length>0">
      <div class="f-left">
        <div class="left-top">
          <div class="clearfix question-breads">
            <div class="bred-left">
              <router-link :to="{name:lastRoute.name}">{{lastRoute.label}}</router-link>
              <span class="slash">></span>
              {{ package.package_name}}
            </div>
            <div class="bred-right">
              <span>{{current.index}}</span>
              <span>/{{list.length}}</span>
            </div>
          </div>
          <div class="mode-tab">
            <Button class="btn btn-left" @click="answerQuestion(0)" :class="{ 'btn-primary' : isAnswer == 0 || isAnswer == 2}">答题模式</Button>
            <Button class="btn btn-right" @click="answerQuestion(1)" :class="{ 'btn-primary' : isAnswer == 1}">背题模式</Button>
          </div>
          <ExamQuestion v-if="current" :ques="current" :readonly="isAnswer > 0" :answer="answer" @change="onChange(current)" @showAnalysis="answerQuestion(2)"></ExamQuestion>
          <div class="clear operate">
            <div class="last-question">
              <a  @click="navPrev"> <i  class="el-icon-arrow-left"></i> 上一题</a>
            </div>
            <div class="cancel-question" @click="collect" v-if="current.collect_id>0">
              <img src="../../images/exam/point/collect-has@3x.png" style="width:17px;" />
              <span class="primary-color">取消收藏</span>
            </div>
            <div class="collect-question" @click="collect" v-else>
              <img src="../../images/exam/point/collect@2x.png" style="width:17px;" />
              <span>收藏</span>
            </div>
            <div class="error-question" @click="onCorrect">
            <img src="../../images/exam/point/hand@2x.png" style="width:15px;" />
              <span>纠错</span>
            </div>
            <div class="next-question">
              <a @click="navNext">下一题 <i  class="el-icon-arrow-right"></i> </a>
            </div>
          </div>

        </div>

        <div class="left-bottom forbid"  v-show="isAnswer>0">
          <div class="right-anser" v-if="current.ques_type != 5">
            <span>正确答案：</span><span class="green">{{current.right_option.join(' ')}}</span>
            <span>您的答案：</span>
              <span :class="setClass(current)" v-if="current.ques_type == 2">
                <font v-for="(option,index) in answer[current.id]" :key="index" >{{chars[option] }} </font>
              </span>
              <span :class="setClass(current)" v-else>{{chars[answer[current.id]] }}</span>
          </div>

          <div class="analysis" :style=" {marginTop: current.ques_type == 5?'30px':0 }">
            <div class="labels">解析：</div>
            <div class="question_analysis" v-html="current.question_analysis"></div>
          </div>
          <div class="follow-apply" style="width:827px;">
              <el-input type="textarea"
                        :rows="4"
                        placeholder="请输入提问内容"
                        v-model="content"></el-input>
              <el-button type="primary"
                        style="margin-top:18px;"
                        @click="add_ask()">提问</el-button>
              <div style="clear:both;"></div>
          </div>
          <!-- 问答 -->
          <div class="answer-box">
            <h3 class="answer-top-title">
              <span>全部回答</span>
            </h3>
            <div class="answer-detail"
                v-for="(item,index) in answerList"
                :key="index">
              <div class="answer-detail-top">
                <img class="answer-avatar"
                    :src="item.avatar"
                    alt />
                <div class="answer-right" style="width:770px">
                  <h3>
                    {{item.nickname || item.real_name}}
                    <span @click.stop="triggerSonPause(item.id,index,1,false,item.is_like)">
                      <img v-if="item.is_like==1"
                          class="answer-pause"
                          src="@/assets/images/news/dianzan_active.png"
                          alt />
                      <img v-else
                          class="answer-pause"
                          src="@/assets/images/news/dianzan@3x.png"
                          alt />
                      {{item.like_number}}
                    </span>
                  </h3>
                  <p class="answer-time">{{item.created_at}}</p>
                  <div>
                    <p class="answer-content"> 
                      <img class="tip_icon"
                           src="@/assets/images/question@2x.png"><span v-html="item.content"></span></p>
                    <div class="answer-img-box"
                        v-if="item.images != ''">
                      <div class="answer-img"
                          v-for="(son,index) in stringToArray(item.images)"
                          :key="index">
                        <img :src="son"
                            @click="showPreview(stringToArray(item.images),index)" />
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="answer-detail-top" v-if="item.a_content">
                <img class="answer-avatar"
                    :src="item.a_avatar" />
                <div class="answer-right" style="width:770px">
                  <h3>
                    {{item.a_real_name}}
                  </h3>
                  <p class="answer-time">{{item.a_created_at}}</p>
                  <div>
                    <p class="answer-content"> 
                      <img class="tip_icon"
                           src="@/assets/images/answer@2x.png"><span v-html="item.a_content"></span></p>
                    <div class="answer-img-box"
                        v-if="item.a_images != ''">
                      <div class="answer-img"
                          v-for="(son,index) in stringToArray(item.a_images)"
                          :key="index">
                        <img :src="son"
                            @click="showPreview(stringToArray(item.a_images),index)" />
                      </div>
                    </div>
                    <div>
                      <el-button type="primary"
                                v-show="item.showBtn"
                                @click="triggerBtn(1,index)">追问</el-button>
                      <el-button style="margin-left:0"
                                v-show="!item.showBtn"
                                @click="triggerBtn(2,index)">取消</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 追问 -->
              <div class="follow-box"
                  v-if="item.child.total>0">
                <div class="follow-item"
                    v-for="(son,sonIndex) in item.child.data"
                    :key="sonIndex">
                  <div class="follow-avatar">
                    <img :src="son.avatar" />
                  </div>
                  <div class="follow-right">
                    <h3>
                      {{son.user_name}}
                      <span @click.stop="triggerSonPause(son.id,index,2,sonIndex,son.is_like)">
                        <img v-if="son.is_like==1"
                            class="follow-pause"
                            src="@/assets/images/news/dianzan_active.png"
                            alt />
                        <img v-else
                            class="follow-pause"
                            src="@/assets/images/news/dianzan@3x.png"
                            alt />
                        {{son.like_number}}
                      </span>
                    </h3>
                    <p class="follow-time">{{son.created_at}}</p>
                    <div class="flolow">
                      <p class="follow-content">
                        <span v-if="son.ait">回复<span class="ait">@{{son.ait}}</span></span>
                        <span v-html="son.content"></span>
                      </p>
                    </div>
                  </div>
                </div>
                <el-pagination background
                              v-if="item.child.total>5"
                              @current-change="((val)=>{childCurrentChange(val,item,index)})"
                              :current-page="item.child.current_page"
                              :page-size="childLimit"
                              layout="prev, pager, next, total, jumper"
                              :total="item.child.total"></el-pagination>
                <div style="clear:both;"></div>
              </div>
              <!-- 追问回复框 -->
              <div class="follow-apply"
                  v-show="!item.showBtn">
                    <el-input type="textarea"
                              :rows="4"
                              placeholder="请输入追问内容"
                              v-model="item.textarea"></el-input>
                    <el-button type="primary"
                              style="margin-top:18px;"
                              @click="reApply(item,index)">追问</el-button>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <Empty v-if="answerList.length==0"
                      class="empty"
                      title="暂无提问！" />
                <el-pagination background
                              @current-change="handleCurrentChange"
                              :current-page="page"
                              :page-size="limit"
                              layout="prev, pager, next, total, jumper"
                              :total="total"
                              v-if="total>limit"></el-pagination>
              </div>
            </div>
          </div>

      <div class="f-right">
        <div class="answer-view">
          <div class="progress">
            <span>答题卡</span>
            <span class="primary-color">{{answerCount}}</span>
            <span>/{{list.length}}</span>
          </div>
          <div class="nums">
            <a
              href="javascript:void(0)"
              class="num"
              :class="{'done':answerState[key]}"
              :key="key"
              v-for="(value,key) in answerState"
              @click="setIndex(key)"
            >{{key}}</a>
          </div>
          <div class="legend">
            <span class="num done"></span>
            <span>已做</span>
            <span class="num"></span>
            <span>未做</span>
          </div>
          <div class="submit" @click="submit()">立即提交</div>
        </div>
      </div>
    </div>
    <el-dialog title="纠错" :visible.sync="showCorrection" width="644px" :center="true">
      <div class="form-item">
        <label>纠错类型：</label>
        <el-radio-group v-model="correction.type">
          <el-radio :label="1">错别字</el-radio>
          <el-radio :label="2">答案解析不匹配</el-radio>
          <el-radio :label="3">解析错误</el-radio>
          <el-radio :label="4">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="form-item">
        <label>纠错内容：</label>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入题目纠错信息，最多不超过500个字"
          v-model="correction.error_msg"
          :maxlength="500"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="showCorrection = false">取消</el-button>
        <el-button type="primary" @click="submitCorrection">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ExamTopicType } from "@/utils/enums";
import ExamQuestion from "../../components/ExamQuestion.vue";
export default {
  name: "ExamExerciseDo",
  components: {
    ExamQuestion
  },
  data() {
    return {
      list: [],
      index: 0,
      answer: {},
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      seconds: 0,
      time: "00:00:00",
      timer: null,
      answerCount: 0,
      answerState: {},
      isPause: false,
      isSubmit: false,
      showCorrection: false,
      isReset: undefined,
      package: {},
      correction: {
        type: "",
        error_msg: ""
      },
      lastRoute: {
        name: this.$route.query.route_name || "Exercise",
        label: this.$route.query.route_label || "章节练习"
      },
      isAnswer: 0,
      answerList:[],
      total:0,
      childLimit:5,
      content:'',
      limit:10,
      page:1,
      canQuit: 0,
      localKey: null
    };
  },
  computed: {
    current() {
      return this.list[this.index];
    }
  },
  async created() {
    let data, questions, history;
    this.localKey = this.$route.query.id + localStorage.getItem('user_id');
    data = await this.$http.get(
      "/api/app/exam/exercise/showQuestion/" +
        this.$route.query.id
    );
    history = await this.$http.get('/api/app/exam/setrecord/?key=' + this.localKey);
    history = JSON.parse(history.value?history.value:'{}');
    let history_question = history.question
    this.package = data.package;
    questions = data.detail[0].question;
    let index = 1;
    this.rightOption = '';
    if (history_question) {
      this.index = history.index;
      let arr_new = [];
      for (let i in history_question) {
        history_question[i].id = i;
        arr_new.push(history_question[i]);
      }
      questions.forEach((i,n)=>{
        this.$set(this.answerState, n+1, 0);
        if (questions[n].ques_type == 2) {
          this.$set(this.answer, questions[n].id, []);
        }
        arr_new.forEach((item,index)=>{
          if(item.id == i.id){
            if(item.user_answer.length){
              this.$set(this.answerState, n+1, 1);
              this.answerCount++;
            }
            if (questions[n].ques_type == 1 || questions[n].ques_type == 3) {
              this.$set(this.answer, questions[n].id, item.user_answer[0]);
            } else if (questions[n].ques_type == 2) {
              this.$set(this.answer, questions[n].id, item.user_answer);
            }
          }
        })
      })
    }
    questions.forEach(ques => {
      if (typeof ques.ques_option === "string") {
        ques.ques_option = JSON.parse(ques.ques_option);
      }
      ques.index = index;
      ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
      ques.right_option = [];
      if( ques.ques_option ) {
        ques.ques_option.forEach((op, i) => {
          if( op.is_right == 1 ) {
            ques.right_option.push(this.chars[i+1])
          }
        });
      }
      if(!history_question){
        this.$set(this.answerState, index, 0);
        if (ques.ques_type == 1 || ques.ques_type == 3) {
          this.$set(this.answer, ques.id, "");
        } else if (ques.ques_type == 2) {
          this.$set(this.answer, ques.id, []);
        } else if (ques.ques_type == 4) {
          let answer = {};
          ques.ques_option.left.forEach(op => {
            answer[op.id] = "";
          });
          this.$set(this.answer, ques.id, answer);
        }
      }
      index++;
    });
    this.list = questions;
    this.timer = setInterval(() => {
      if (this.isPause || this.isSubmit) {
        return;
      }
      this.seconds++;
      let seconds = this.seconds;
      let time = "";
      let hours = parseInt(seconds / 60 / 60);
      time += hours < 10 ? "0" + hours.toString() : hours.toString();
      let minutes = parseInt((seconds / 60) % 60);
      time +=
        ":" + (minutes < 10 ? "0" + minutes.toString() : minutes.toString());
      let surplus_seconds = seconds % 60;
      time +=
        ":" +
        (surplus_seconds < 10
          ? "0" + surplus_seconds.toString()
          : surplus_seconds.toString());
      this.time = time;
    }, 1000);
  },
  methods: {
    onChange(ques) {
      if (ques.ques_type == 1 || ques.ques_type == 3) {
        if (this.answer[ques.id] != 0 && this.answerState[ques.index] != 1) {
          this.$set(this.answerState, ques.index, 1);
          this.answerCount++;
        }
      } else if (ques.ques_type == 2) {
        if (this.answer[ques.id].length > 0) {
          this.answer[ques.id].sort(function (a,b){ return a-b });
          if (this.answerState[ques.index] != 1) {
            this.$set(this.answerState, ques.index, 1);
            this.answerCount++;
          }
        } else {
          if (this.answerState[ques.index] == 1) {
            this.$set(this.answerState, ques.index, 0);
            this.answerCount--;
          }
        }
      } else if (ques.ques_type == 4) {
        let state = 1;
        let lineAnswer = this.answer[ques.id];
        for (let key in lineAnswer) {
          if (lineAnswer[key] == 0) {
            state = 0;
            break;
          }
        }
        if (state == 1 && this.answerState[ques.index] == 0) {
          this.$set(this.answerState, ques.index, state);
          this.answerCount++;
        }
      }
    },
    navPrev() {
      if (this.index == 0) {
        this.$info("已至第一题");
        return;
      }
      this.index--;
      this.isAnswer=0;
      this.mathJax();
    },
    navNext() {
      if (this.index == this.list.length - 1) {
        this.$info("已至最后一题");
        return;
      }
      this.index++;
      this.isAnswer=0;
      this.mathJax();
    },
    async submit() {
      this.isSubmit = true;
      let count = 0;
      let quesCount = 0;
      for (let key in this.answerState) {
        quesCount++;
        if (this.answerState[key] == 0) {
          count++;
        }
      }
      // 如果没有做任何题
      if (count == quesCount) {
        await this.$promiseConfirm(
          '<span class="dialog-info">您已做<span class="primary-color">0</span>道题，是否退出答题？</span>',
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "继续答题",
            cancelButtonText: "退出答题",
            distinguishCancelAndClose: true,
            callback: action => {
              if (action == "cancel") {
                this.$router.go(-1);
              }
            }
          }
        );
        return;
      }
      if (count > 0) {
        await this.$promiseConfirm(
          '<span class="dialog-info">您还有<span class="primary-color">' +
            count +
            "</span>道题未做，确定要提交吗？</span>",
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "确认交卷",
            cancelButtonText: "继续答题",
            callback: () => {
              this.isSubmit = false;
            }
          }
        );
      } else {
        await this.$promiseConfirm(
          '<span class="dialog-info">确定要提交吗？</span>',
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "确认交卷",
            cancelButtonText: "继续答题",
            callback: () => {
              this.isSubmit = false;
            }
          }
        );
      }
      let result = await this.setAnswer();
      // isReset 有值才传，否则不传
      const params = {
        question: JSON.stringify(result),
        paper_id: this.$route.query.id,
        report_time_long: this.seconds
      };
      if (this.isReset !== undefined) {
        params.is_reset = this.isReset;
      }
      let data = await this.$http.post(
        "/api/app/exam/exercise/storeReport",
        params
      );
      this.setRecord(this.localKey,'{}')
      this.canQuit = 1;
      if (this.$route.query.retry) {
        this.$router.push({
          name: "ExamPointAnalysis",
          query: {
            id: data.report_id,
            route_name: this.$route.query.route_name,
            route_label: this.$route.query.route_label
          }
        });
      } else {
        this.$router.push({
          name: "ExamExerciseReport",
          query: {
            id: data.report_id,
            route_name: this.$route.query.route_name,
            route_label: this.$route.query.route_label,
            number: this.$route.query.number
          }
        });
      }
    },
    setAnswer (){
      let result = {};
      for (let key in this.answer) {
        let ques = this.list.filter(q => q.id == key)[0];
        let state = this.answerState[ques.index];
        if (state == 0 && this.$route.query.number != 15) {
          continue;
        }
        result[ques.id] = { report_type: ques.ques_type };
        if (state == 1) {
          if (ques.ques_type == 1 || ques.ques_type == 3) {
            result[ques.id].user_answer = [this.answer[key]];
          } else if (ques.ques_type == 2) {
            result[ques.id].user_answer = this.answer[key];
          } else if (ques.ques_type == 4) {
            result[ques.id].user_answer = this.answer[key];
          }
        } else {
          result[ques.id].user_answer = [];
        }
      }
      return result;
    },
    async submitCorrection() {
      if (this.correction.type === "") {
        this.$info("请选择纠错类型");
        return;
      }
      if (this.correction.error_msg === "") {
        this.$info("请输入纠错内容");
        return;
      }
      await this.$http.post("/api/app/myExam/correction", {
        ques_id: this.current.id,
        type: this.correction.type,
        error_msg: this.correction.error_msg
      });
      this.showCorrection = false;
      this.$success("提交成功");
    },
    async collect() {
      if (this.current.collect_id > 0) {
        await this.$http.put(
          "/api/app/collect/cancel/" + this.current.collect_id + "/3"
        );
        this.current.collect_id = null;
        this.$success("取消收藏成功");
      } else {
        this.current.collect_id = await this.$http.post("/api/app/collect", {
          ques_id: this.current.id,
          type: 3
        });
        this.$success("收藏成功");
      }
    },
    onCorrect() {
      this.showCorrection = true;
      this.correction.error_msg = "";
      this.correction.type = "";
    },
    answerQuestion(isAns) {
      this.isAnswer = isAns;
      if(this.isAnswer>0){
        this.getAnswerList();
      }
    },
    stringToArray (str) {
      if (str == "" || str == null || str == undefined) {
        return [];
      } else {
        let arr = str.split(",");
        return arr;
      }
    },
    add_ask(){
      if(!this.content){
        this.$message.warning("请填写提问内容");
        return;
      }
      this.$http.post("/api/app/ask/zask",{question_id:this.current.id,content:this.content,exam_type: 1, exam_id: this.$route.query.exercise_id}).then(res=>{
        this.content = '';
        this.getAnswerList();
      })
    },
    showPreview (list, index) {
      this.prviewList = list;
      this.isShowImageDialog = true;
    },
    getAnswerList(){
      this.$http.get("/api/app/ask/zaskList",{question_id:this.current.id,page:this.page,limit:this.limit}).then(res=>{
        res.list.forEach(item => {
          item.showBtn = true;
          item.textarea = '';
        })
        this.answerList = res.list;
        this.total = res.total;
        console.log(this.answerList)
      })
    },
    async triggerSonPause (_id, _index, _type, _sonIndex) {
      let data = await this.$http.post("/api/app/ask/clickLike", {
        ask_id: this.current.id,
        comment_id: _id,
        user_type: 1
      });
      let item = this.answerList[_index];
      if (_type == 1) {
        item.is_like = !this.answerList[_index].is_like;
        if(item.is_like){
          item.like_number++;
        } else{
          item.like_number--;
        }
      } else {
        item.child.data[_sonIndex].is_like = !this.answerList[_index].child.data[_sonIndex].is_like;
        if(item.child.data[_sonIndex].is_like){
          item.child.data[_sonIndex].like_number++;
        } else{
          item.child.data[_sonIndex].like_number--;
        }
      }
      this.$set(this.answerList, _index, item)
    },
    triggerBtn (_type, _index) {
      let item = this.answerList[_index];
      item.showBtn = _type == 1 ? false : true;
      // this.$set(this.answerList._index,item)
    },
    handleCurrentChange (page) {
      this.page = page;
      this.getAnswerList();
    },
    async childCurrentChange (page, item, index) {
      let data = await this.getChildAnswer(item.a_id, page);
      let fData = this.answerList[index];
      fData.child.data = data.list;
      // this.$set(this.answerList[index].child, 'current_pages', 1)
    },
    getChildAnswer (_id, _page) {
      let data = this.$http.get('/api/app/ask/zaskChildList', {
        id: _id,
        page: _page,
        limit: this.childLimit
      })
      return data;
    },
    async reApply (_item, index) {
      await this.$http.post('/api/app/ask/zaskQuestioning', {
        parent_id: _item.a_id,
        content: _item.textarea
      })
      await this.$success("追问成功");
      let data = await this.getChildAnswer(_item.a_id, 1);
      let fData = _item;
      fData.child = data;
      fData.showBtn = true;
      fData.textarea = '';
      fData.child.data = data.list;
    },
    setIndex(key){
      this.index = key-1;
      this.mathJax();
    },
    mathJax(){
      let _this = this;
      if (this.MathJax.isMathjaxConfig) { // 判断是否初始配置，若无则配置。
        this.MathJax.initMathjaxConfig()
      }
      setTimeout(function(){
        _this.MathJax.MathQueue()// 传入组件id，让组件被MathJax渲染
      },1000)
    },
    async setRecord (key,value) {
      await this.$http.post('/api/app/exam/setrecord',{key:key,value:value}).then(res=>{
        console.log(res)
      })
    },
    setClass(current){
      let user_answer = [],right_answers = [];
      let right_answer = JSON.parse(current.right_answer);
      right_answer.forEach(item=>{
        right_answers.push(this.chars[item.id]);
      })
      if(current.ques_type==2){
        this.answer[current.id].forEach(option=>{
          user_answer.push(this.chars[option])
        })
        if(user_answer.join(',')==right_answers.join(',')){
          return 'green';
        } else{
          return 'red';
        }
      } else{
        let user_answer = this.chars[this.answer[current.id]]
        if(user_answer == right_answers.join(',')){
          return 'green';
        } else {
          return 'red';
        }
      }
    }
  },
  mounted() {
    this.mathJax();
  },
  async beforeRouteLeave (to, from, next) {
    if(!this.canQuit){
      await this.$promiseConfirm('退出后是否保存答题结果？若不保存下次答题将从头开始',{
            type: "warning",
            confirmButtonText: '保存记录',
            cancelButtonText: '直接退出',
            distinguishCancelAndClose: true,
            callback: action => {
              if (action == "cancel") {
                this.setRecord(this.localKey,'{}')
                // localStorage.removeItem(this.localKey)
                this.canQuit = 1;
                next();
              }
            }
          }
        ).then(()=>{
          let result = this.setAnswer();
          let params = {
            index: this.index,
            question: result
          }
          if(JSON.stringify(result) != "{}"){
            this.setRecord(this.localKey,JSON.stringify(params))
            // localStorage.setItem(this.localKey,JSON.stringify(params));
          }
          next();
        })
      next(false)
    } else {
      next();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
@import "../../css/question.scss";
.main-content {
  margin-top: 30px;
  margin-bottom: 94px;
  > .f-left {
    width: 890px;
    background-color: white;
    /deep/ .options {
      padding-left: 25px;
    }
  }
  > .f-right {
    width: 290px;
  }
}
.time {
  color: #333333;
  margin-bottom: 15px;
  .f-left,
  .f-right {
    padding: 12px 0;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  .f-left {
    background-color: white;
    width: 177px;
  }
  .f-right {
    background-color: white;
    width: 96px;
    cursor: pointer;
    font-size: 16px;
    > span {
      display: flex;
      align-items: center;
    }
    .icon {
      margin-right: 10px;
    }
    span {
      margin: 0 !important;
      color: #595959 !important;
    }
    .el-icon-caret-right {
      font-size: 20px;
      margin-right: 6px;
    }
  }
  span:nth-child(2) {
    margin: 0 15px;
  }
  span:last-child {
    color: $primary-color;
  }
}
.answer-view {
  box-sizing: border-box;
  border-radius: 5px;
  .progress {
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    font-size: 16px;
    .primary-color {
      font-size: 18px;
    }
  }
  .nums {
    background-color: white;
    padding: 0 12px 12px 12px;
    margin-bottom: 15px;
  }
  .num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 3px;
    color: #b8b8b8;
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
    &.done {
      background-color: $primary-color;
      color: white;
    }
    &:nth-child(7) {
      margin-right: 0;
    }
  }
  .legend {
    background-color: white;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .num {
      margin-top: 0;
      margin-right: 5px;
      width: 14px;
      height: 14px;
      cursor: default;
    }
    span:nth-child(2) {
      margin-right: 35px;
    }
  }
  .submit {
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    padding: 12px 0;
    cursor: default;
  }
}
.topic-nav a {
  width: 137px;
  height: 45px;
  display: inline-block;
  border: 1px solid rgba(233, 236, 236, 1);
  border-radius: 4px;
  background-color: white;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}
.dialog-info {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 63px;
    margin-right: 27px;
  }
}
.operate {
  font-size: 16px;
  color: #595959;
  font-weight: 400;
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  div {
    cursor: pointer;
  }
  .collect-question, .cancel-question,.error-question {
    display: inline-flex;
  }
  .collect-question img, .cancel-question img,.error-question img{
    margin-right: 5px;
    width: 17px;
    height: 17px;
    padding-top: 3px;
  }
}
</style>
<style lang="scss">
.dialog-info {
  color: #999999;
  font-size: 20px;
  .primary-color {
    font-size: 30px;
  }
}
.dialog-point {
  width: 576px;
  .el-message-box__content {
    margin: 36px 0 50px 0;
  }
}
.question-breads{
  height: 80px;
  border-bottom: 1px solid #F4F4F4;
  line-height: 80px;
  padding: 0 24px;
  font-size: 18px;
  font-weight: 500;
  .slash{
    padding: 0 5px;
  }
  .bred-left{
    float: left;
  }
  .bred-right{
    float: right;
  }
}
.mode-tab {
  margin: 30px auto;
  width: 225px;
  .btn {
    width: 111px;
    height: 40px;
    outline: none;
    border: 1px solid $primary-color;
    color: $primary-color;
    font-size: 16px;
  }
  .btn-primary {
    background: $primary-color;
    color: rgba(255, 255, 255, 1);
  }
  .btn-left{
    border-radius: 5px 0px 0px 5px;
  }
  .btn-right{
    border-radius: 0px 5px 5px 0px;
  }
}
.left-bottom{
  border-top: 20px solid #f1f2f5;
  margin-bottom: 30px;
  .right-anser{
    margin: 30px 25px;
    background-color: #F7F7F7;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #666666;
    padding: 0 30px;
    span{
      &.red{
        color: #FC0D1B;
        font-weight: 400;
        padding-right: 50px;
      }
      &.green{
        color: #0F7F12;
        font-weight: 400;
        padding-right: 50px;
      }
    }
  }
  .analysis{
    display: flex;
    margin-left: 30px;
    font-size: 16px;
    color: #666666;
    .labels{
      width: 50px;
    }
    .question_analysis{
      max-width:786px;
    }
    /deep/{
      img{
        max-width: 100%;
      }
    }
  }
}
.tip_icon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
