<template>
  <div class="main-container">
    <div class="bread">
      <router-link :to="{name:lastRoute.name}">{{lastRoute.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">答题详情</span>
    </div>
    <div class="main-content clear">
      <div class="f-left">
        <ExamQuestion
          v-for="ques in list"
          :key="ques.id"
          :ques="ques"
          :answer="answer"
          :readonly="true"
          :hasAnalysis="true"
          :showOperate="true"
        ></ExamQuestion>
      </div>
      <div
        class="f-right"
        :style="{position:fixed?'fixed':'static',right:fixedRight+'px',top:'84px'}"
      >
        <div class="answer-view">
          <div class="progress">
            <span>答题卡</span>
            <span class="primary-color">{{answerCount}}</span>
            <span>/{{total}}</span>
          </div>
          <div class="nums">
            <a
              class="num"
              :class="{'right':answerState[key]==1,'error':answerState[key]==0,'none':answerState[key]==0}"
              :key="key"
              v-for="(value,key) in answerState"
              @click="scrollTo(key)"
            >{{key}}</a>
          </div>
          <div class="legend">
            <span class="num right"></span>
            <span>正确{{rightCount}}</span>
            <span class="num error"></span>
            <span>错误{{errorCount}}</span>
            <span class="num none"></span>
            <span>未做{{noneCount}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ExamTopicType } from "@/utils/enums";
import ExamQuestion from "../../components/ExamQuestion.vue";
export default {
  name: "ExamExerciseAnalysis",
  components: {
    ExamQuestion
  },
  data() {
    return {
      list: [],
      answer: {},
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      fixed: false,
      fixedRight: 0,
      total: 0,
      answerCount: 0,
      answerState: {},
      rightCount: 0,
      errorCount: 0,
      noneCount: 0,
      lastRoute: {
        name: this.$route.query.route_name || "Exercise",
        label: this.$route.query.route_label || "章节练习"
      }
    };
  },
  async created() {
    let data = await this.$http.get(
      "/api/app/exam/exercise/reportDetail",
      {
        report_id: this.$route.query.id,
        paper_id: this.$route.query.paper_id
      }
    );
    let index = 1;
    let question = data.detail[0].question;
    question.forEach(ques => {
      ques.id = ques.ques_id;
      ques.index = index;
      ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
      if (typeof ques.ques_option === "string") {
        ques.ques_option = JSON.parse(ques.ques_option);
      }
      let answers = [];
      if (ques.user_answer) {
        let user_answer;
        if (typeof ques.user_answer === "string") {
          user_answer = JSON.parse(ques.user_answer);
        } else {
          user_answer = ques.user_answer;
        }
        user_answer.forEach(a => {
          if (ques.ques_type == 4) {
            if (a.answer) {
              answers.push(a.id + ")" + this.chars[a.answer] + " ");
            }
          } else {
            if (a.id) {
              answers.push(this.chars[a.id]);
            }
          }
        });
        ques.user_answer = answers.join("");
      }
      if (answers.length > 0) {
        this.answerCount++;
        if (ques.is_right == 1) {
          this.rightCount++;
        } else {
          this.errorCount++;
        }
        this.$set(this.answerState, ques.index, ques.is_right == 1 ? 1 : 0);
      } else {
        this.$set(this.answerState, ques.index, -1);
        this.noneCount++;
      }
      if (ques.ques_type == 1 || ques.ques_type == 3) {
        let id = ques.ques_option.filter(o => o.is_right==1)[0].id;
        this.$set(this.answer, ques.ques_id, id);
        ques.answer = this.chars[id];
      } else if (ques.ques_type == 2) {
        let ids = [];
        let answers = [];
        ques.ques_option
          .filter(o => o.is_right==1)
          .forEach(o => {
            ids.push(o.id);
            answers.push(this.chars[o.id]);
          });
        this.$set(this.answer, ques.ques_id, ids);
        ques.answer = answers.join("");
      } else if (ques.ques_type == 4) {
        let answer = {};
        let answers = [];
        ques.ques_option.left.forEach(op => {
          answer[op.id] = op.is_right;
          answers.push(op.id + ")" + this.chars[op.is_right]);
        });
        this.$set(this.answer, ques.ques_id, answer);
        ques.answer = answers.join(" ");
      }
      index++;
    });
    this.total = index - 1;
    this.list = question;
  },
  mounted() {
    this.$nextTick(() => {
      let $ = window.$;
      $(window)
        .scroll(() => {
          this.setFixed();
        })
        .resize(() => {
          this.setFixed();
        });
    });
  },
  methods: {
    setFixed() {
      let $ = window.$;
      if ($(window).scrollTop() + 84 > 203) {
        this.fixed = true;
        this.fixedRight = ($(window).width() - 1200) / 2;
        if (this.fixedRight < 0) {
          this.fixedRight = 0;
        }
      } else {
        this.fixed = false;
      }
    },
    scrollTo(id) {
      let $ = window.$;
      let top = $("#" + id).offset().top - 84;
      $(window).scrollTop(top);
    },
    mathJax(){
      let _this = this;
      if (this.MathJax.isMathjaxConfig) { // 判断是否初始配置，若无则配置。
        this.MathJax.initMathjaxConfig()
      }
      setTimeout(function(){
        _this.MathJax.MathQueue()// 传入组件id，让组件被MathJax渲染
      },1000)
    }
  },
  mounted() {
    this.mathJax();
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.main-content {
  margin-bottom: 50px;
  .f-left {
    width: 890px;
    background-color: white;
  }
  .f-right {
    width: 290px;
  }
}
.section {
  .title {
    background-color: #f7f7f7;
    padding: 16px 25px;
    font-size: 18px;
    color: #262626;
    span {
      margin-right: 5px;
    }
  }
}
.answer-view {
  border: 1px solid rgba(233, 236, 236, 1);
  box-sizing: border-box;
  border-radius: 5px;
  .progress {
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    font-size: 16px;
    .primary-color {
      font-size: 18px;
    }
  }
  .nums {
    background-color: white;
    padding: 0 12px 12px 12px;
    margin-bottom: 15px;
  }
  .num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 3px;
    color: #b8b8b8;
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
    &.right {
      background-color: #30c596;
      color: white;
    }
    &.error {
      background-color: #ff5500;
      color: white;
    }
    &:nth-child(7) {
      margin-right: 0;
    }
  }
  .legend {
    background-color: white;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .num {
      margin-top: 0;
      margin-right: 5px;
      width: 14px;
      height: 14px;
      cursor: default;
    }
    span:nth-child(2),
    span:nth-child(4) {
      margin-right: 40px;
    }
  }
}
</style>
