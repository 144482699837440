<template>
  <div class="main-container">
    <div class="bread" v-if="$route.query.type=='s'">
      <router-link :to="{name:'ExamSimulation'}">模拟考试</router-link>
      <span class="slash">/</span>
      <span class="current">考试说明</span>
    </div>
    <div class="bread" v-else>
      <router-link :to="{name:'ExamPackagePractice'}">套卷练习</router-link>
      <span class="slash">/</span>
      <span class="current">试卷介绍</span>
    </div>
    <div class="main-content">
      <div class="name">{{name}}</div>
      <div class="detail">
        <div class="main">
          <div>
            <label>试卷分类</label>
            <span>{{exam_classify_name}}</span>
          </div>
          <div>
            <label>题目数量</label>
            <span>{{question_num}}题</span>
          </div>
          <div>
            <label>满分</label>
            <span>{{parseFloat(score)}}分</span>
          </div>
          <div>
            <label>通过分数</label>
            <span>{{parseFloat(pass_score)}}分</span>
          </div>
          <div>
            <label>作答时间</label>
            <span>{{limit_time>0?(limit_time/60)+"分":"无限制"}}</span>
          </div>
          <div v-if="$route.query.type=='s'">
            <label>考试次数</label>
            <span>已考{{have_done_count}}次</span>
          </div>
          <div>
            <label>试卷简介</label>
            <span>{{exam_description}}</span>
          </div>
        </div>
        <div class="center operate">
          <router-link
            v-if="$route.query.type=='s'"
            class="lnk"
            :to="{name:'ExamPackagePracticeDo',query:{id:$route.query.id,type:'s',action:'start'}}"
          >开始考试</router-link>
          <router-link
            v-else
            class="lnk"
            :to="{name:'ExamPackagePracticeDo',query:{id:$route.query.id}}"
          >开始练习</router-link>
          <router-link
            v-if="$route.query.type=='s'&&have_done_count>0"
            class="lnk"
            :to="{name:'ExamPackagePracticeAnalysis',query:{paper_id:$route.query.id,type:'s',report_id}}"
          >查看上次考试成绩</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExamPackagePracticeDes",
  data() {
    return {
      name: "",
      exam_classify_name: "",
      question_num: 0,
      score: "",
      pass_score: "",
      limit_time: 0,
      exam_description: "",
      have_done_count: 0,
      repeat_times: 0,
      report_id: 0
    };
  },
  async created() {
    let data;
    if (this.$route.query.type == "s") {
      data = await this.$http.get(
        "/api/app/exam/packageSimulation/" + this.$route.query.id
      );
    } else {
      data = await this.$http.get(
        "/api/app/exam/packagePractice/" + this.$route.query.id
      );
    }
    this.name = data.name;
    this.exam_classify_name = data.exam_classify_name;
    this.question_num = data.question_num;
    this.score = data.score;
    this.pass_score = data.pass_score;
    this.limit_time = data.limit_time;
    this.exam_description = data.exam_description;
    this.have_done_count = data.have_done_count;
    this.repeat_times = data.repeat_times;
    this.report_id = this.$route.query.report_id || data.report_id;
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.main-container {
  margin-top: 30px;
}
.bread {
  margin-bottom: 30px;
}
.main-content {
  background-color: white;
}
.name {
  margin: 0 40px;
  text-align: center;
  font-size: 20px;
  color: #333333;
  font-weight: 500;
  padding: 36px 0;
  border-bottom: 1px dashed rgba(222, 222, 222, 1);
}
.detail {
  padding-top: 80px;
  font-size: 14px;
  margin-bottom: 40px;
  padding-bottom: 134px;
  background-image: url(../../images/exam/package/bottom.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  .main {
    padding-left: 220px;
    > div {
      margin-bottom: 20px;
    }
  }
  label {
    color: #8c8c8c;
    width: 60px;
    display: inline-block;
    text-align: right;
    margin-right: 50px;
  }
}
.operate {
  padding-top: 60px;
}
.lnk {
  background-color: $primary-color;
  color: white;
  padding: 16px 34px;
  font-size: 18px;
  margin-right: 60px;
  &:last-child {
    margin-right: 0;
  }
}
</style>
