<template>
  <div class="point-row">
    <div class="content">
      <div class="name">
        <span
          class="icon"
          :class="'icon'+level.toString()"
          v-if="data.children&&data.children.length"
          @click="toggleExpand"
        >
          <i class="el-icon-circle-plus" v-if="!data.expand"></i>
          <i class="el-icon-remove" v-else></i>
        </span>
        <span class="icon icon-point" v-else></span>
        <span>{{data.name}}</span>
      </div>
      <div class="topic">{{data.finished_amount||0}}/{{data.question_amount}}</div>
      <div class="percent">{{$pub.toFixed(data.correct_rate*100,2)}}%</div>
      <div class="operate">
        <div v-if="data.question_amount>0">
          <router-link
            v-if="(data.finished_amount||0)<data.question_amount"
            :to="{name:'ExamPointDo',query:{id:data.id,number:number}}"
          >开始答题</router-link>
          <router-link
            v-else
            class="active"
            :to="{name:'ExamPointDo',query:{id:data.id,number:number}}"
          >重新答题</router-link>
        </div>
        <a class="disabled" v-else>开始答题</a>
      </div>
    </div>
    <div class="child" v-if="data.expand">
      <ExamPointRow
        v-for="child in data.children"
        :key="child.id"
        :data="child"
        :number="number"
        :level="level+1"
      ></ExamPointRow>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExamPointRow",
  props: {
    data: {
      type: Object
    },
    number: {
      type: Number
    },
    level: {
      type: Number,
      default: 1
    }
  },
  created() {
    this.$set(this.data, "expand", false);
  },
  methods: {
    toggleExpand() {
      this.data.expand = !this.data.expand;
      this.$emit("onExpand", this.data.id);
    }
  },
  watch: {
    data() {
      this.$set(this.data, "expand", false);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.point-row {
  margin-bottom: 20px;
}
.content {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 34px;
  .name {
    flex-grow: 1;
    margin-left: 42px;
    position: relative;
    .icon {
      position: absolute;
      top: 2px;
      &.icon2 {
        left: 1px;
        i {
          font-size: 17px;
        }
      }
      &.icon3 {
        left: 2px;
        i {
          font-size: 15px;
        }
      }
    }
    .icon-point {
      width: 11px;
      height: 11px;
      background: $primary-color;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      left: 4px;
    }
    i {
      font-size: 20px;
      cursor: pointer;
    }
    .el-icon-circle-plus {
      color: $primary-color;
    }
    .el-icon-remove {
      color: $primary-color;
    }
    span:last-child {
      margin-left: 30px;
    }
  }
  .topic {
    width: 64px;
    text-align: center;
    margin-right: 278px;
  }
  .percent {
    width: 60px;
    text-align: center;
    margin-right: 278px;
  }
  .operate {
    width: 90px;
    text-align: center;
    margin-right: 42px;
    a {
      display: block;
      width: 88px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid $primary-color;
      color: $primary-color;
      font-size: 14px;
      cursor: pointer;
      &.active {
        background-color: $primary-color;
        color: white;
      }
      &.disabled {
        color: #8c8c8c;
        border-color: #8c8c8c;
        cursor: not-allowed;
      }
    }
  }
}
.child {
  margin-top: 20px;
}
</style>