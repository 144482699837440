<template>
  <div class="main-container">
    <div class="bread">
      <router-link :to="{name:lastRoute.name}">{{lastRoute.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">答题报告</span>
    </div>
    <div class="count-content" :class="{'seq':number==='0'}">
      <div class="counter right">
        <div>
          <span class="number">{{detail.right_count}}</span>
          <span>题</span>
        </div>
        <div class="label">正确数</div>
      </div>
      <div class="counter error">
        <div>
          <span class="number">{{detail.wrong_count}}</span>
          <span>题</span>
        </div>
        <div class="label">错题数</div>
      </div>
      <div class="counter not" v-if="number!=='0'">
        <div>
          <span class="number">{{detail.not_count}}</span>
          <span>题</span>
        </div>
        <div class="label">未做数</div>
      </div>
      <div class="counter percent">
        <div>
          <span
            class="number"
          >{{(detail.right_count*100/(detail.right_count+detail.wrong_count+detail.not_count)).toFixed(0)}}</span>
          <span>%</span>
        </div>
        <div class="label">正确率</div>
      </div>
    </div>
    <div class="number-container">
      <div class="header clear">
        <div class="f-left">
          <span>答题卡</span>
          <span class="primary-color">{{detail.right_count+detail.wrong_count}}</span>
          <span>/{{detail.right_count+detail.wrong_count+detail.not_count}}</span>
        </div>
        <div class="f-right">
          <span>正确</span>
          <span class="legend right"></span>
          <span>错误</span>
          <span class="legend error"></span>
          <span v-if="number!=='0'">未做</span>
          <span v-if="number!=='0'" class="legend not"></span>
        </div>
      </div>
      <div class="legends">
        <span
          v-for="(item,index) in detail.reports"
          :key="index"
          class="legend"
          :class="{'right':item.is_right==1,'error':item.is_right==2,'not':item.is_right==3}"
        >{{index+1}}</span>
      </div>
    </div>
    <div class="clear">
      <el-button
        type="primary"
        @click="$router.push({name:'ExamPointAnalysis',query:{id:$route.query.id,route_name: $route.query.route_name,route_label: $route.query.route_label}})"
      >查看解析</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExamPointReport",
  data() {
    return {
      detail: {},
      lastRoute: {
        name: this.$route.query.route_name || "ExamPoint",
        label: this.$route.query.route_label || "考点专练"
      },
      number: ""
    };
  },
  async created() {
    this.number = this.$route.query.number;
    if (this.number !== undefined && this.number !== null) {
      this.number = this.number.toString();
    }
    let data = await this.$http.get(
      "/api/app/report/examPoint/" + this.$route.query.id
    );
    data.reports.forEach(ques => {
      if (typeof ques.ques_option === "string") {
        ques.ques_option = JSON.parse(ques.ques_option);
      }
      if (ques.user_answer) {
        if (typeof ques.user_answer === "string") {
          ques.user_answer = JSON.parse(ques.user_answer);
        }
        if (ques.ques_option.left) {
          if (
            ques.user_answer.filter(a => a.answer !== 0 && a.answer !== "")
              .length == 0 ||
            ques.user_answer.length == 0
          ) {
            data.not_count++;
            ques.is_right = 3;
          }
        } else {
          if (
            ques.user_answer.filter(a => a.id !== 0 && a.id !== "").length ==
              0 ||
            ques.user_answer.length == 0
          ) {
            data.not_count++;
            ques.is_right = 3;
          }
        }
      }
    });
    data.wrong_count = data.reports.filter(q => q.is_right === 2).length;
    this.detail = data;
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.count-content {
  margin-top: 30px;
  background-color: white;
  padding: 40px;
  overflow: hidden;
  margin-bottom: 30px;
  &.seq {
    padding: 40px 100px;
    .counter {
      margin-right: 145px;
    }
  }
  .counter {
    width: 234px;
    height: 142px;
    border: 1px solid rgba(183, 183, 183, 1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    float: left;
    margin-right: 58px;
    &:last-child {
      margin-right: 0;
    }
    .number {
      font-size: 22px;
      margin-right: 10px;
    }
    .label {
      color: #595959;
      margin-top: 15px;
    }
    &.right .number {
      color: $primary-color;
    }
    &.error .number {
      color: red;
    }
    &.not .number {
      color: black;
    }
    &.percent .number {
      color: #09a8eb;
    }
  }
}
.number-container {
  margin-top: 30px;
  font-size: 16px;
  border-radius: 10px;
  .header {
    background-color: #f7f7f7;
    padding: 19px 24px;
    .primary-color {
      font-size: 20px;
    }
    .f-right {
      display: flex;
      align-items: center;
      .legend {
        margin: 0 31px 0 9px;
      }
    }
  }
  .legend {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    &.right {
      background-color: #30c596;
    }
    &.error {
      background-color: #ff5500;
    }
    &.not {
      background-color: #b7b7b7;
    }
  }
  .legends {
    padding: 32px 20px 0 0;
    background-color: white;
    margin-bottom: 24px;
    .legend {
      margin-bottom: 32px;
      margin-left: 20px;
      width: 30px;
      height: 30px;
      border-radius: 3px;
      text-align: center;
      line-height: 30px;
      color: white;
    }
  }
}
.el-button--primary {
  float: right;
  margin-bottom: 24px;
}
</style>
