<template>
  <div class="main-container">
    <div class="bread" v-if="$route.query.type=='s'">
      <router-link :to="{name:lastRouteSim.name}">{{lastRouteSim.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">成绩单</span>
    </div>
    <div class="bread" v-else>
      <router-link :to="{name:lastRoutePra.name}">{{lastRoutePra.label}}</router-link>
      <span class="slash">/</span>
      <span class="current">成绩单</span>
    </div>
    <div class="main-content">
      <div class="name bold">{{detail.name}}</div>
      <div class="clear">
        <div class="score f-left" :class="detail.is_pass?'score-green':'score-red'">
          <div class="num">{{detail.point}}</div>
          <div class="pass">{{detail.is_pass?'通过':'不通过'}}</div>
        </div>
        <div class="line f-left"></div>
        <div class="detail f-left">
          <div>
            <label>试卷总分：</label>
            <span>{{parseFloat(detail.score)}}分</span>
          </div>
          <div>
            <label>通过分数：</label>
            <span>{{parseFloat(detail.pass_score)}}分</span>
          </div>
          <div>
            <label>试题数量：</label>
            <span>{{detail.ques_count}}题</span>
          </div>
          <div>
            <label>回答正确：</label>
            <span>{{detail.right_count}}题</span>
          </div>
          <div>
            <label>考试用时：</label>
            <span>{{detail.time}}</span>
          </div>
          <div>
            <label>交卷时间：</label>
            <span>{{detail.created_at|tsFormat("MM/dd hh:mm:ss")}}</span>
          </div>
        </div>
      </div>
      <div
        class="button"
        @click="$router.push({name:'ExamPackagePracticeAnalysis',query:{report_id:detail.id,paper_id:detail.package_id,type:$route.query.type}})"
      >查看答题解析</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExamPackagePracticeScore",
  data() {
    return {
      detail: {},
      lastRouteSim: {
        name: this.$route.query.route_name || "ExamSimulation",
        label: this.$route.query.route_label || "模拟考试"
      },
      lastRoutePra: {
        name: this.$route.query.route_name || "ExamPackagePractice",
        label: this.$route.query.route_label || "套卷练习"
      }
    };
  },
  async created() {
    window.scrollTo(0, 0);
    let data;
    if (this.$route.query.type == "s") {
      data = await this.$http.get(
        "/api/app/exam/simulation/reportBase/" + this.$route.query.id
      );
    } else {
      data = await this.$http.get(
        "/api/app/exam/practice/reportBase/" + this.$route.query.id
      );
    }
    let seconds = data.report_time_long;
    let time = "";
    let hours = parseInt(seconds / 60 / 60);
    if (hours > 0) {
      time += (hours < 10 ? "0" + hours.toString() : hours.toString()) + "时";
    }
    let minutes = parseInt((seconds / 60) % 60);
    if (minutes > 0) {
      time +=
        (minutes < 10 ? "0" + minutes.toString() : minutes.toString()) + "分";
    }
    let surplus_seconds = seconds % 60;
    time +=
      (surplus_seconds < 10
        ? "0" + surplus_seconds.toString()
        : surplus_seconds.toString()) + "秒";
    data.time = time;
    this.detail = data;
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.main-content {
  background-color: white;
  margin-bottom: 78px;
  padding-bottom: 98px;
}
.name {
  padding: 33px;
  text-align: center;
  margin: 0 41px;
  border-bottom: 1px dashed #dedede;
  margin-bottom: 118px;
}
.score {
  width: 148px;
  height: 151px;
  text-align: center;
  margin-right: 125px;
  margin-left: 327px;
  margin-top: 12px;
  .num {
    font-size: 54px;
    padding-top: 37px;
  }
  .pass {
    font-size: 14px;
    font-weight: 500;
    color: white;
    padding-top: 21px;
  }
}
.score-green {
  background-image: url(../../images/exam/package/score-green.png);
  .num {
    color: #52c45b;
  }
}
.score-red {
  background-image: url(../../images/exam/package/score-red.png);
  .num {
    color: red;
  }
}
.line {
  width: 1px;
  height: 143px;
  background: #ebebea;
  margin-right: 129px;
  margin-top: 28px;
}
.detail {
  font-size: 14px;
  > div {
    margin-bottom: 22px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  label {
    color: #8c8c8c;
  }
}
.button {
  background-color: $primary-color;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  margin: 166px auto 0 auto;
  cursor: default;
}
</style>