<template>
  <div class="main-container">
    <div class="bread">
      <router-link :to="{name:'ExamIndex'}">在线测评</router-link>
      <span class="slash">/</span>
      <span class="current">考点专练</span>
    </div>
    <div class="search bold clear" v-if="classifyList.length>0" @click="showSelectBankModal=true">
      <div class="all f-left">
        <img src="../../images/exam/point/four-point.png" />
        <span>题库选择</span>
      </div>
      <div>{{bank.name}}</div>
    </div>
    <div class="point-content">
      <el-radio-group v-model="number" @change="onNumberChange">
        <el-radio :label="15">随机15道</el-radio>
        <!-- <el-radio :label="0">按顺序做题</el-radio> -->
      </el-radio-group>
      <div>
        <div class="header">
          <div class="name">考点</div>
          <div class="topic">答题情况</div>
          <div class="percent">正确率</div>
          <div class="operate">操作</div>
        </div>
        <div class="empty" v-if="points.length==0">
          <div>
            <img src="../../images/exam/package/empty@2x.png" />
          </div>
          <div>请稍后，考点专练稍后就来</div>
        </div>
        <ExamPointRow
          v-else
          class="point-border"
          v-for="point in points"
          :key="point.id"
          :data="point"
          :number="number"
          @onExpand="onExpand"
        ></ExamPointRow>
      </div>
    </div>
    <el-dialog title="题库选择" :visible.sync="showSelectBankModal" width="1000px" class="bank-select">
      <div class="center tip">您好：请选择您要参加的题库，以便于我们更好的为您提供服务。</div>
      <div class="modal-classify-select">
        <div class="first-classify-container">
          <div
            v-for="item in classifyList"
            :key="item.id"
            class="firt-classify"
            :class="{'active':bankSelect.classify.id==item.id}"
            @click="changeModalClassify(item)"
          >{{item.name}}</div>
        </div>
        <div class="select-tab">
          <div class="item">
            <div class="right">
              <ul>
                <li
                  :class="bankSelect.subClassify.id == item.id?'active':'' "
                  v-for="item in bankSelect.classify.child"
                  :key="item.id"
                  @click="changeModalSubClassify(item)"
                >
                  {{item.name}}
                  <div
                    v-if="bankSelect.subClassify.id == item.id&&bankSelect.bankList.length>0"
                    class="select-tab-arrow"
                  ></div>
                </li>
              </ul>
              <div class="child" v-if="bankSelect.bankList.length">
                <p
                  :class="item.id === bank.id?'active':'' "
                  v-for="item in bankSelect.bankList"
                  :key="item.id"
                  @click="changeModalBank(item)"
                >{{item.name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div></div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ExamPointRow from "../../components/ExamPointRow.vue";
export default {
  name: "ExamPoint",
  components: {
    ExamPointRow
  },
  data() {
    return {
      classifyList: [],
      classifyCurrent: {},
      classifyId: 0,
      bankList: [],
      bank: 0,
      showSelectBankModal: false,
      bankSelect: {
        classify: {},
        subClassify: {},
        bankList: []
      },
      points: [],
      number: 15
    };
  },
  async created() {
    let data = await this.$http.get("/api/app/get/quesBank/classify");
    this.classifyList = data;
    if (this.classifyList.length > 0) {
      this.classifyCurrent = this.classifyList[0];
      this.bankSelect.classify = this.classifyList[0];
      if (
        this.bankSelect.classify.child &&
        this.bankSelect.classify.child.length
      ) {
        this.bankSelect.subClassify = this.bankSelect.classify.child[0];
        this.bankSelect.bankList = await this.getBank(
          this.bankSelect.subClassify.id
        );
      }
    }
    let selectedBank = this.$storage().get("selectedBank");
    if (selectedBank) {
      this.bank = selectedBank;
      this.showSelectBankModal = false;
      await this.getPoint();
    } else {
      this.showSelectBankModal = true;
    }

    // let number = this.$storage().get("selectedNumber");
    // if (number === undefined || number === null) {
    //   number = 15;
    // }
    // this.number = number;
  },
  methods: {
    async getBank(classify) {
      if (classify == 0) {
        return [];
      } else {
        let data = await this.$http.get("/api/app/quesBank/" + classify);
        return data;
      }
    },
    async getPoint() {
      let data = await this.$http.get(
        "/api/app/examPoint/" +
          (this.classifyId || this.classifyCurrent.id) +
          "/" +
          this.bank.id
      );
      this.points = data;
    },
    async changeModalClassify(classify) {
      this.bankSelect.classify = classify;
      if (classify.child && classify.child.length) {
        this.bankSelect.subClassify = classify.child[0];
        this.bankSelect.bankList = await this.getBank(
          this.bankSelect.subClassify.id
        );
      } else {
        this.bankSelect.bankList = [];
      }
    },
    async changeModalSubClassify(subClassify) {
      this.bankSelect.subClassify = subClassify;
      this.bankSelect.bankList = await this.getBank(subClassify.id);
    },
    async changeModalBank(bank) {
      this.$storage().set("selectedBank", bank);

      this.bank = bank;
      this.bankList = this.bankSelect.bankList;
      this.classifyId = this.bankSelect.subClassify.id;
      this.classifyCurrent = this.bankSelect.classify;
      this.showSelectBankModal = false;
      await this.getPoint();
    },
    onExpand(id) {
      this.points.forEach(p => {
        if (p.id != id) {
          p.expand = false;
        }
      });
    },
    onNumberChange() {
      this.$storage().set("selectedNumber", this.number);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.search {
  margin-top: 30px;
  background-color: white;
  padding: 24px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #595959;
  text-align: center;
  cursor: pointer;
  .all {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
}
.select-tab {
  margin: 0;
  padding: 0;
  border: none;
}
.select-tab .item .left p {
  height: 22px;
  line-height: 22px;
}
.select-tab .item .left p,
.select-tab .item .left {
  width: auto;
}
.select-tab .item .right li {
  color: #595959;
  &.active {
    color: white;
  }
}
.select-tab .item .right .child {
  margin-bottom: 0;
}
.modal-classify-select {
  display: flex;
}
.point-content {
  background-color: white;
  padding: 24px;
  margin-bottom: 60px;
  .header {
    background-color: #fbfbfb;
    font-size: 16px;
    height: 55px;
    line-height: 55px;
    display: flex;
    color: #595959;
    margin-top: 24px;
    margin-bottom: 20px;
    .name {
      margin-left: 70px;
    }
  }
  .name {
    flex-grow: 1;
  }
  .topic {
    width: 64px;
    text-align: center;
    margin-right: 278px;
  }
  .percent {
    width: 60px;
    text-align: center;
    margin-right: 278px;
  }
  .operate {
    width: 90px;
    text-align: center;
    margin-right: 42px;
  }
  .point-border {
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 10px;
    padding: 26px 0;
    margin-bottom: 20px;
  }
}
.empty {
  text-align: center;
  font-size: 18px;
  color: #8c8c8c;
  padding: 134px 0;
  img {
    margin-bottom: 30px;
  }
}
.bank-select {
  .modal-classify-select {
    border-top: 1px solid #f1f1f1;
  }
  .tip {
    font-size: 16px;
    color: #595959;
    margin-bottom: 36px;
  }
  .first-classify-container {
    padding-top: 30px;
    padding-right: 30px;
    border-right: 1px solid #f1f1f1;
  }
  .firt-classify {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 5px;
    font-size: 14px;
    color: rgba(89, 89, 89, 1);
    cursor: pointer;
    padding: 8px 16px;
    margin-bottom: 20px;
    white-space: nowrap;
    &:hover,
    &.active {
      background-color: $primary-color-light;
      color: $primary-color;
    }
  }
  .select-tab {
    padding-top: 30px;
    .item .right {
      margin-left: 33px;
    }
  }
}
</style>